import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { ThemeProvider } from '@material-ui/styles';
import DashboardNavbar from './DashboardNavbar';
import serverConstants from '../assets/data/serverConstants';
import Theme from '../hoc/UI/Theme';
import Styles from '../hoc/UI/Styles';

const Statistics = (props) => {
  const classes = Styles();

  useEffect(() => {
    getStatsData();
  }, []);

  const [statsData, setStatsData] = useState({
    town: '',
    customer: '',
    masterKitchen: '',
    ePanipuriKartz: '',
  });

  const [Loading, setLoading] = useState(false);

  const handleChange = (event) => {
    event.target.value < 0
      ? setStatsData({ ...statsData, [event.target.name]: 0 })
      : setStatsData({ ...statsData, [event.target.name]: event.target.value });
  };

  const getStatsData = () => {
    let getStatsUrl = serverConstants.serverUrl + '/getFigures';

    axios
      .get(getStatsUrl)
      .then((response) => {
        setStatsData(response.data);
      })
      .catch((error) => {
        alert('Something went wrong!! Please try again.');
      });
  };

  const saveStatsData = (event) => {
    event.preventDefault();
    setLoading(true);
    let saveStatsUrl = serverConstants.serverUrl + 'saveFigures';
    const header = {
      Authorization: sessionStorage.getItem('epanipuriToken'),
    };
    console.log(header);
    axios
      .post(saveStatsUrl, statsData, { headers: header })
      .then((response) => {
        setLoading(false);
        console.log(response);
      })
      .catch((error) => {
        setLoading(false);
        alert('Something went wrong!! Please try again.');
      });
  };

  return (
    <React.Fragment>
      <DashboardNavbar />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={10} sm={8} md={6}>
          <form onSubmit={saveStatsData}>
            <TextField
              className={classes.textField1}
              variant="outlined"
              type="number"
              id="standard-number"
              label="Town"
              name="town"
              value={statsData.town}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              className={classes.textField1}
              variant="outlined"
              type="number"
              id="standard-number"
              label="Customer"
              name="customer"
              value={statsData.customer}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              className={classes.textField1}
              variant="outlined"
              type="number"
              id="standard-number"
              label="Master Kitchen"
              name="masterKitchen"
              value={statsData.masterKitchen}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              className={classes.textField1}
              variant="outlined"
              type="number"
              id="standard-number"
              label="E Panipuri Kartz"
              name="ePanipuriKartz"
              value={statsData.ePanipuriKartz}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Grid item xs={10}>
              <Grid item container justify="center">
                {Loading ? (
                  <CircularProgress />
                ) : (
                  <ThemeProvider theme={Theme}>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={Loading}
                      variant="contained"
                      className={classes.button2}
                    >
                      Save
                    </Button>
                  </ThemeProvider>
                )}
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Statistics;
