import axios from "axios";
import serverConstants from "../assets/data/serverConstants";
import { GET_PENDING_FORM } from "../store/actions/actionTypes";

export const getPendingForms = (dispatch, condition = "in_review") => {
  const url =
    serverConstants.serverUrl + `v2/getApplicationForms?status=${condition}`;

  const headers = {
    Authorization: sessionStorage.getItem("epanipuriToken"),
  };
  return axios.get(url, { headers }).then((response) => {
    dispatch({
      type: GET_PENDING_FORM,
      payload: response.data.forms,
    });
  });
};

export const acceptRejectForm = (payload) => {
  const acceptRejectFormUrl =
    serverConstants.serverUrl + "v2/acceptApplicationForms";
  const param = {
    formId: payload.formId,
    location: payload.location,
  };
  const header = {
    Authorization: sessionStorage.getItem("epanipuriToken"),
  };
  return axios.post(acceptRejectFormUrl, param, { headers: header });
};

export const rejectForm = (payload) => {
  const rejectFormUrl = serverConstants.serverUrl + "v2/rejectApplicationForms";
  const param = {
    formId: payload.formId,
  };
  const header = {
    Authorization: sessionStorage.getItem("epanipuriToken"),
  };
  return axios.post(rejectFormUrl, param, { headers: header });
};
