import axios from 'axios';
import serverConstants from '../assets/data/serverConstants';
import {GET_STOCKS,GET_LOGS} from '../store/actions/actionTypes';

export const getStocks = (dispatch) => {
    const url = serverConstants.serverUrl + 'getStockUnit';
    const headers = {
        Authorization: sessionStorage.getItem('epanipuriToken'),
      };
    return axios.get(url, {headers}).then((response) => {
        dispatch({
            type: GET_STOCKS,
            payload: response?.data || []
        })
    });
}


export const updateStock = (quantity, stockId) => {
    const url = serverConstants.serverUrl + 'increaseStockUnit';
    const headers = {
        Authorization: sessionStorage.getItem('epanipuriToken'),
      };
    const data = {
        quantity,
        stockId
    }
    return axios.post(url, data, {headers}).then((response) => {
        return new Promise((res, rej) => {
            return res(response);
        });
    });
}

export const decreaseStock = (quantity, stockId) => {
    const url = serverConstants.serverUrl + 'decreaseStockUnit';
    const headers = {
        Authorization: sessionStorage.getItem('epanipuriToken'),
      };
    const data = {
        quantity,
        stockId
    }
    return axios.post(url, data, {headers}).then((response) => {
        return new Promise((res, rej) => {
            return res(response);
        });
    });
}
export const createStock = (payload) => {
    const url = serverConstants.serverUrl + 'createStockUnit';
    const headers = {
        Authorization: sessionStorage.getItem('epanipuriToken'),
      };
    const data = {
        description:payload.description,
        name:payload.name,
        quantity:payload.quantity,
        price:payload.price,
        
    }
    return axios.post(url, data, {headers}).then((response) => {
        return new Promise((res, rej) => {
            return res(response);
        });
    });
}

export const getLogs = (dispatch) => {
    const url = serverConstants.serverUrl + 'getStockLogs';
    const headers = {
        Authorization: sessionStorage.getItem('epanipuriToken'),
      };
    return axios.get(url, {headers}).then((response) => {
        dispatch({
            type: GET_LOGS,
            payload: response?.data || []
        })
    });
}