import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DashboardNavbar from './DashboardNavbar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Theme from '../hoc/UI/Theme';
import { ThemeProvider } from '@material-ui/styles';
import serverConstants from '../assets/data/serverConstants';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getPricing, updatePricing } from '../api/OrderApi';
import SearchBar from 'material-ui-search-bar';
import moment from 'moment';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    paper: {
        width: '90%',
        margin: 'auto'
    },
    button: {
        // justifyContent:'flex-end'
    }
});

const OrderDetail = (props) => {
    const classes = useStyles();


    const [gameList, setGameList] = useState();
    const [pagesData, setPageData] = useState();
    const [popup, setPopup] = React.useState(false);
    const [values, setValues] = useState({
        bookNumber: '',
        pageNumber: '',
        // endTime: '',
        // number:'',
        // bookSKU:'',
    });
    const [Loading, setLoading] = useState(false);

    useEffect(() => {
        console.log('this.props.history.location.state?.data', props.history.location.state?.data);
        getOrderDetail();
    }, []);

    const getOrderDetail = async () => {
        let getPricingUrl = serverConstants.baseURL + '/api/order/getOrderByGame/' + props.history.location.state?.data.gameID;
        const headers = { "Content-Type": "application/json", 'ngrok-skip-browser-warning': 'true' };
        return axios.get(getPricingUrl, { headers: headers }).then(response => {
            console.log('response', response.data.data);
            setGameList(response.data.data);
        }).catch(error => {
            // alert("No data found")
        })

    }

    const openPopup = () => {
        setPopup(true);
        setLoading(false);
    };

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const checkValidation = () => {
        if (values.bookNumber == '') {
            alert("Please enter book number")
            return false
        } else if (values.bookNumber == '') {
            alert("Please enter page number")
            return false
        } 
        // else if (values.endTime == '') {
        //     alert("Please enter end time")
        //     return false
        // }
        // else if (values.totalPage == '') {
        //     alert("Please enter total page")
        //     return false
        // }

        return true
    }

    const updateData = () => {
        setLoading(true);


        if (!checkValidation()) {
            return
        }

        const addGameData = {
            "id": props.history.location.state?.data.gameID,
            "bookNumber": values.bookNumber,
            "pageNumber": values.pageNumber
        }

        let addGame = serverConstants.baseURL + '/api/order/manualDraw';
        const headers = { "Content-Type": "application/json", 'ngrok-skip-browser-warning': 'true' };
        return axios.post(addGame, addGameData, { headers: headers }).then(response => {
            console.log('response', response.data);
            // setGameList(response.data.data);
            // getOrderDetail();
            alert("Draw declared successfully!")
            closePopup()
        }).catch(error => {
            alert("Something went wrong, please try again!")
            closePopup()
            // alert("No data found")
        })
    }

    const closePopup = () => {
        setPopup(false);
    }

    const onViewOrder = (item) => {
        // history.push('/OrderDetail',{data:item})
        setPopup(true)
    }

    return (
        <React.Fragment>
            <DashboardNavbar />
            <br />
            <br />
            <br />

            <Paper
                className={classes.paper}
                elevation={3}
                style={{ marginTop: '2rem' }}
            >
                {/* <div style={{ display: 'flex', justifyContent: 'space-between', padding: 5 }}>
                <h3 style={{}}>{"Game List"}</h3>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        onClick={() => {
                            openPopup();
                        }}
                    >
                        {' '}
                        Add New Game
                    </Button>
                </div> */}
                {/* <TableCell align="left"> */}
                                       
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 10 }}>
                {props.history.location.state?.data.status == "pending" ?    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        onClick={() => setPopup(true)}
                    >
                        Manual Draw
                    </Button>: <h3>{"Draw declared"}</h3>}
                </div> 
                                    {/* </TableCell> */}
                <Table
                    className={classes.table}
                    size="small"
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <th>Sl.No</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Book number</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Total Bid Amount</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Total Bid Quantity</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Approx Winning</th>
                            </TableCell>
                            {/* <TableCell align="left">
                                <th>Status</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Created</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Action</th>
                            </TableCell> */}
                            {/* <TableCell align="left">
                <th>Modify</th>
              </TableCell> */}
                        </TableRow>
                    </TableHead>
                    {gameList &&
                        gameList.map((row, index) => (
                            <TableBody>
                                <TableRow key={row.uid} onClick={() => {
                                    let gameData = gameList;
                                    gameData[index].isExpand = !gameData[index].isExpand;

                                    setGameList([...gameData])
                                }} hover={true}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="left">{row.bookNumber}</TableCell>
                                    <TableCell align="left">{row.total}</TableCell>
                                    {/* <TableCell align="left">{`${row.startTime} - ${row.endTime}`}</TableCell> */}
                                    <TableCell align="left">{row.totalQua}</TableCell>
                                    <TableCell align="left">{row.totalWinning}</TableCell>
                                </TableRow>
                                {row.isExpand && row.pages.length > 0 ?
                                    <TableRow>
                                        <TableCell colSpan="3">
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            <th>Sl.No</th>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <th>Page number</th>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <th>Total Bid Amount</th>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <th>Total Bid Quantity</th>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <th>Approx Winning</th>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {row.pages.map((a,i) => {
                                                       return <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                {i + 1}
                                                            </TableCell>
                                                            <TableCell align="left">{a.pageNumber}</TableCell>
                                                            <TableCell align="left">{a.total}</TableCell>
                                                            <TableCell align="left">{a.totalQua}</TableCell>
                                                            <TableCell align="left">{a.totalWinning}</TableCell>
                                                        </TableRow>
                                                    })}

                                                </TableBody>
                                            </Table>
                                        </TableCell>
                                    </TableRow>

                                    : null}

                            </TableBody>
                        ))}
                </Table>
            </Paper>
            <Dialog
                open={popup}
                onClose={() => setPopup(false)}
                aria-labelledby="form-dialog-title" >
                <DialogTitle id="form-dialog-title">
                    Manual Draw
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="BookNumber"
                        type="text"
                        name="bookNumber"
                        value={values.bookNumber}
                        margin="dense"
                        fullWidth
                        multiline
                        rowsMax={10}
                        required={true}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Page Number"
                        type="number"
                        name="pageNumber"
                        value={values.pageNumber}
                        margin="dense"
                        fullWidth
                        required={true}
                        onChange={handleChange}
                    />
                    {/* <TextField
                        label="Book SKU"
                        type="text"
                        name="bookSKU"
                        value={values.bookSKU}
                        margin="dense"
                        fullWidth
                        required={true}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Total Page"
                        type="number"
                        name="totalPage"
                        value={values.totalPage}
                        margin="dense"
                        fullWidth
                        required={true}
                        onChange={handleChange}
                    /> */}
                    
                </DialogContent>
                <DialogActions>
                    {Loading ? (
                        <CircularProgress />
                    ) : (
                        <ThemeProvider theme={Theme}>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                className={classes.button}
                                onClick={closePopup}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                className={classes.button}
                                onClick={updateData}
                            >
                                Save
                            </Button>
                        </ThemeProvider>
                    )}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
            ? state.auth.user
            : JSON.parse(sessionStorage.getItem('lotteryUser')),
    };
};

export default connect(mapStateToProps)(OrderDetail);
