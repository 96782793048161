import React, { Component } from 'react';
import { Route, Switch, Redirect, Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { CssBaseline } from "@material-ui/core";
import Login from './components/Login';
import Home from './components/Home';
import SessionExpired from './components/SessionExpired';
import Orders from './components/Orders';
import Statistics from './components/Statistics';
import Pricing from './components/Pricing';
import UploadBlog from './components/UploadBlog'
import history from "./hoc/utils/history";
import * as actions from './store/actions/index';
import ManageBlog from './components/ManageBlog';
import ApproveForm from './components/ApproveForms';
import ManageStock from './components/manageStock';
import RetainerInvoice from './components/RetainerInvoice';
import BookList from './components/BookList';
import GameList from './components/GameList';
import OrderDetail from './components/OrderDetail';
import BalanceHisotry from './components/BalanceHisotry';

class App extends Component {

  showExpiredPopup = () => {
    const {response = {}} = this.props.apiError;
    if (response.status && response.status === 401 && this.props.showExpiredPopup) {
      return true;
    }
    return false;
  }

  render() {

    let routes = (
      <Switch>
        <Route path="/" exact component={Login} />
        <Redirect to="/" />
      </Switch>     
    );

    if(this.props.isAuthenticated?.id) {
      routes = (
        <Switch>
          <Route path="/home" component={Home} />
          <Route path="/user" component={ApproveForm} />
          <Route path="/booklist" component={BookList} />
          <Route path="/gameList" component={GameList} />
          <Route path="/orderDetail" component={OrderDetail} />
          <Route path="/balanceHistory" component={BalanceHisotry} />
          <Route path="/manageBlog" component={ManageBlog} />
          <Route path="/" exact component={Home} />
          <Redirect to="/" />
        </Switch>
      )
    }
    else {
      routes = (
        <Switch>          
          <Route path="/" exact component={Login} />
          <Redirect to="/" />
        </Switch>
      )
    }

    return (
      <React.Fragment>
        <CssBaseline />
        {this.showExpiredPopup() ? <SessionExpired logout={this.props.logout} /> : null}
        <Router history={history}>
          {routes}
        </Router>
      </React.Fragment>
    );

  }

}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.user || JSON.parse(sessionStorage.getItem('lotteryUser')),
    apiError: state.auth.apiError || {},
    showExpiredPopup: state.auth.showExpiredPopup
  };
};

const mapDispatchToProps = dispatch => {
  return {
      logout: () => dispatch(actions.logout())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
