import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DashboardNavbar from './DashboardNavbar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Theme from '../hoc/UI/Theme';
import { ThemeProvider } from '@material-ui/styles';
import serverConstants from '../assets/data/serverConstants';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getPricing, updatePricing } from '../api/OrderApi';
import SearchBar from 'material-ui-search-bar';
import moment from 'moment';
import history from "../hoc/utils/history";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    paper: {
        width: '90%',
        margin: 'auto'
    },
    button: {
        // justifyContent:'flex-end'
    }
});

const GameList = (props) => {
    const classes = useStyles();


    const [gameList, setGameList] = useState();
    const [popup, setPopup] = React.useState(false);
    const [values, setValues] = useState({
        date: '',
        startTime: '',
        endTime: '',
        // number:'',
        // bookSKU:'',
    });
    const [Loading, setLoading] = useState(false);

    useEffect(() => {
        getGameList();
    }, []);

    const getGameList = async () => {
        let getPricingUrl = serverConstants.baseURL + '/api/game/gameList';
        const headers = { "Content-Type": "application/json", 'ngrok-skip-browser-warning': 'true' };
        return axios.get(getPricingUrl, { headers: headers }).then(response => {
            console.log('response', response.data.data);
            setGameList(response.data.data);
        }).catch(error => {
            // alert("No data found")
        })

    }

    const openPopup = () => {
        setPopup(true);
        setLoading(false);
    };

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const checkValidation = () => {

        let startTime = moment(values.startTime,'HH:mm A').toDate().getTime();
        let endTime = moment(values.endTime,'HH:mm A').toDate().getTime();
        console.log(' START TIME WITH DATE ',startTime);
        console.log(' END TIME WITH DATE ',endTime);

        if (values.date == '') {
            alert("Please enter date")
            return false
        } else if (values.startTime == '') {
            alert("Please enter start time")
            return false
        } else if (values.endTime == '') {
            alert("Please enter end time")
            return false
        }else if(endTime < startTime){
            alert("Please select end time after of start time")
            return false
        }
        // else if (values.totalPage == '') {
        //     alert("Please enter total page")
        //     return false
        // }

        return true
    }

    const updateData = () => {
        setLoading(true);

        if (!checkValidation()) {
            setLoading(false);
            return
        }

        console.log(' START TIME ', values.startTime);
        console.log(' END TIME ', values.endTime);
        const addGameData = {
            "date": moment(values.date, "YYYY-MM-DD").format("MM/DD/YYYY"),
            "startTime": values.startTime,
            "endTime": values.endTime
        }

        let addGame = serverConstants.baseURL + '/api/game/addGameDetails';
        const headers = { "Content-Type": "application/json", 'ngrok-skip-browser-warning': 'true' };
        return axios.post(addGame, addGameData, { headers: headers }).then(response => {
            console.log('response', response.data);
            // setGameList(response.data.data);
            getGameList();
            alert("Game data added successfully!")
            closePopup()
        }).catch(error => {
            alert("Something went wrong, please try again!")
            closePopup()
            // alert("No data found")
        })
    }

    const closePopup = () => {
        setPopup(false);
    }

    const onViewOrder = (item) => {
        history.push("/orderDetail", { data: item })
    }

    return (
        <React.Fragment>
            <DashboardNavbar />
            <br />
            <br />
            <br />

            <Paper
                className={classes.paper}
                elevation={3}
                style={{ marginTop: '2rem' }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: 5 }}>
                    <h3 style={{}}>{"Game List"}</h3>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        onClick={() => {
                            openPopup();
                        }}
                    >
                        {' '}
                        Add New Game
                    </Button>
                </div>
                <Table
                    className={classes.table}
                    size="small"
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <th>Sl.No</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Game name</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Date</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>From-To</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Status</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Created</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Action</th>
                            </TableCell>
                            {/* <TableCell align="left">
                <th>Modify</th>
              </TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {gameList &&
                            gameList.map((row, index) => (
                                <TableRow key={row.uid}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{row.date}</TableCell>
                                    <TableCell align="left">{`${row.startTime} - ${row.endTime}`}</TableCell>
                                    <TableCell align="left">{row.status}</TableCell>
                                    <TableCell align="left">{row.createdAt}</TableCell>
                                    <TableCell align="left">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            className={classes.button}
                                            onClick={() => onViewOrder(row)}
                                        >
                                            View Order
                                        </Button>
                                    </TableCell>
                                    {/* <TableCell align="left">
                    <ThemeProvider theme={Theme}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        onClick={() => openPopup(row.uid,row.name,row.price)}
                      >
                        {' '}
                        Update
                      </Button>
                    </ThemeProvider>
                  </TableCell> */}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </Paper>
            <Dialog
                open={popup}
                onClose={() => setPopup(false)}
                aria-labelledby="form-dialog-title" >
                <DialogTitle id="form-dialog-title">
                    Add Game
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="Date"
                        type="date"
                        name="date"
                        value={values.date}
                        margin="dense"
                        fullWidth
                        // multiline
                        // rowsMax={10}
                        required={true}
                        onChange={handleChange}
                    />

                    <TextField
                        label="Start Time"
                        type="time"
                        name="startTime"
                        value={values.startTime}
                        margin="dense"
                        fullWidth
                        required={true}
                        onChange={handleChange}
                    />

                    <TextField
                        label="End Time"
                        type="time"
                        name="endTime"
                        value={values.endTime}
                        margin="dense"
                        fullWidth
                        required={true}
                        onChange={handleChange}
                    />

                    {/* <TextField
                        label="Total Page"
                        type="number"
                        name="totalPage"
                        value={values.totalPage}
                        margin="dense"
                        fullWidth
                        required={true}
                        onChange={handleChange}
                    /> */}

                </DialogContent>
                <DialogActions>
                    {Loading ? (
                        <CircularProgress />
                    ) : (
                        <ThemeProvider theme={Theme}>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                className={classes.button}
                                onClick={closePopup}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                className={classes.button}
                                onClick={updateData}
                            >
                                Save
                            </Button>
                        </ThemeProvider>
                    )}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
            ? state.auth.user
            : JSON.parse(sessionStorage.getItem('lotteryUser')),
    };
};

export default connect(mapStateToProps)(GameList);
