import React from "react";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { ThemeProvider } from "@material-ui/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DashboardNavbar from "./DashboardNavbar";
import "../hoc/UI/orders.css";
import "axios";
import Theme from "../hoc/UI/Theme";
import { getRetainerInvoices,markAsPaid } from "../api/retainerInvoiceApi";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

const styles = (theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing.unit,
  },
});
class RetainerInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "Retainer Invoices",
      isPopupOpened: false,
      selectedFormId: undefined,
      selectedActionName: undefined,
      isDetailsPopupOpened: false,
      formDetails: [],
      fullWidth: true,
      maxWidth: "sm",
      location:'',
      invoiceId:''
    };
  }
  componentDidMount() {
    this.props.getRetainerInvoices();
  }

  getColumns = () => {
    return [
      "Email",
      "Invoice Number",
      "Amount",
      "Updated On",
      "Action"
    ];
  };

  getActionButton = (formId, formdetails) => {
    return (
      <React.Fragment>
        <Button
          style={{ marginRight: "20px" }}
          onClick={() => {
            this.onActionClicked(formdetails, "Paid");
          }}
          variant="contained"
          color="primary"
          disabled={formdetails.invoice_status ==='paid'}
        >
          Mark As Paid
        </Button>
      </React.Fragment>
    );
  };

  getDate = (date) => {
    let newDate = new Date(date);
    return newDate.toDateString();
  };


  onActionClicked = (id, Action) => {
    if (Action === "Paid") {
      let details = [];
      details.push(id);

    this.setState({
        ...this.state,
        invoiceId:id.invoice_id
    })

      const param ={
        invoiceId:id.invoice_id
      }

      this.props.markAsPaid(param).then((res)=>{
        this.props.getRetainerInvoices();
        this.setState({
            isPopupOpened: false,
            selectedBlogId: undefined,
            selectedBlogName: undefined,
            invoiceId:'',
          });
      })

    }
  };

  getInvoicesToShow = () => {
    let forms = [];
    forms = this.props.retainerInvoices.map((form) => {
      return [
        form.email,
        form.invoice_number,
        form.amount,
        this.getDate(form.timestamp),
        this.getActionButton(form.invoice_id, form),
        form.invoice_id,        
        form.customer_id,
        form.amount
      ];
    });

    return forms;
  };

  render() {
    const { classes } = this.props;
    const options = {
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
      selectableRows: "none",
      //   expandableRows: true,
      //   expandableRowsHeader:false,
      //   expandableRowsHideExpander: false,
      //   renderExpandableRow: (rowData, rowMeta) => {
      //     console.log(rowData, rowMeta);
      //     return <div>hello</div>;
      //   },
    };

    return (
      <React.Fragment>
        <ThemeProvider theme={Theme}>
          <DashboardNavbar />
          <br />
          <br />
          <br />
          <div id="retainerTable">
            <MUIDataTable
              title={this.state.pageTitle}
              data={this.getInvoicesToShow()}
              columns={this.getColumns()}
              options={options}
            />
            {/* {this.getPopup()}
            {this.getDetailsPopup()} */}
          </div>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

// ApproveForm.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

const mapStateToProps = (state) => {
  const {
    retainerInvoices: { retainerInvoices },
  } = state;
  return {
    user: state.auth.user
      ? state.auth.user
      : JSON.parse(sessionStorage.getItem("lotteryUser")),
    orders: state.orders,
    costings: state.costings,
    blogs: state.blogs,
    forms:state.forms,
    retainerInvoices
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRetainerInvoices: () => {
        getRetainerInvoices(dispatch);
    },
    markAsPaid: (payload) => {
      return markAsPaid(payload);
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RetainerInvoice));
