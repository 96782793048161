import React, { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { CircularProgress, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { ThemeProvider } from '@material-ui/styles';
import history from '../hoc/utils/history';
import LoginNavbar from './LoginNavbar';
import serverConstants from '../assets/data/serverConstants';
import epanipuricart from '../assets/images/epanipuricart.png';
import * as actions from '../store/actions/index';
import Theme from '../hoc/UI/Theme';
import Styles from '../hoc/UI/Styles';
import constants from '../assets/data/serverConstants';

const Login = (props) => {
  const classes = Styles();

  const [Loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const login = (event) => {
    event.preventDefault();
    if (!validate()) return;
    setLoading(true);
    const { email, password } = values;
    // let firebaseUrl = serverConstants.firebaseSigninUrl;
    const authData = {
      email: email,
      password: password,
      // returnSecureToken: true,
    };
    axios
    .post(constants.baseURL+ "/api/loginAdmin", authData, { headers: {"Content-Type":"application/json"} })
    .then((response) => {
      setLoading(false); 
      console.log(' CHECK LOGIN RESPONSE ',response.data.data);
      props.login(response.data.data);
      history.push('/home');
    })
    .catch((error) => {
      console.log('error login',error);
      setLoading(false);
      alert("User not found")
      sessionStorage.removeItem('epanipuriToken');
      sessionStorage.removeItem('lotteryUserId');
      sessionStorage.removeItem('epanipuriExpirationDate');
    });
  };

  const validate = () => {
    const newErrors = {};

    if (values.email === '') newErrors.email = 'Please enter an Email Id';
    else newErrors.email = '';

    if (values.password === '') newErrors.password = 'Please enter a password';
    else newErrors.password = '';

    setErrors({ ...errors, ...newErrors });

    if (newErrors.email !== '' || newErrors.password !== '') return false;

    return true;
  };

  return (
    <React.Fragment>
      <LoginNavbar />
      <Grid container justify="center">
        <Grid item xs={10} container justify="center">
          <img
            src={epanipuricart}
            alt="epanipuricart"
            className={classes.mainLogo}
          />
        </Grid>
        <Grid item xs={10} sm={5} md={6}>
          <form onSubmit={login}>
            <TextField
              className={classes.textField}
              label="Email"
              variant="outlined"
              value={values.email}
              helperText={errors.email}
              error={errors.email ? true : false}
              name="email"
              onChange={handleChange}
            />
            <br />
            <TextField
              className={classes.textField}
              label="Password"
              type="password"
              variant="outlined"
              helperText={errors.password}
              error={errors.password ? true : false}
              value={values.password}
              name="password"
              onChange={handleChange}
            />
            <br />
            <div className={classes.root}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}
              >
                <br />
                <br />
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.paper}>
                    <NavLink to="/register" exact>
                      Forgot Password?
                    </NavLink>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} >
                  <Grid item container justify="center">
                    {Loading ? (
                      <CircularProgress />
                    ) : (
                      <ThemeProvider theme={Theme}>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={Loading}
                          variant="contained"
                          className={classes.button}
                          error={errors.password ? true : false}
                        >
                          Login
                        </Button>
                      </ThemeProvider>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </form>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (user) => dispatch(actions.login(user)),
  };
};

export default connect(null, mapDispatchToProps)(Login);
