import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import reportWebVitals from './reportWebVitals';
import App from './App';
import authReducer from './store/reducers/auth';
import orderReducer from './store/reducers/orderReducer';
import  manageBlogReducer from './store/reducers/manageBlogReducer';
import pendingFormReducer from './store/reducers/approveFormReducer';
import stocksReducer from './store/reducers/stocksReducer';
import logsReducer from './store/reducers/logsReducer';
import retainerInvoicesReducer from './store/reducers/retainerInvoicesReducer'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  orders: orderReducer,
  blogs: manageBlogReducer,
  forms: pendingFormReducer,
  stocks:stocksReducer,
  logs:logsReducer,
  retainerInvoices: retainerInvoicesReducer
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  store.dispatch({
      type: 'API_ERROR',
      payload: error,
  })
  return Promise.reject(error);
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
