import * as actionTypes from '../actions/actionTypes';

const initialState = {
    retainerInvoices: []
};

const retainerInvoicesReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_RETAINER_INVOICE:
            return {
                ...state,
                retainerInvoices: action.payload
            }

        default:
            return state;
    }
};

export default retainerInvoicesReducer;