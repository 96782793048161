import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DashboardNavbar from './DashboardNavbar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Theme from '../hoc/UI/Theme';
import { ThemeProvider } from '@material-ui/styles';
import Box from '@material-ui/core/Box'
import CreateIcon from '@material-ui/icons/Create';
import ImageIcon from '@material-ui/icons/Image'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import { updateBlog } from '../api/OrderApi';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    paper: {
        width: '70%',
        margin: 'auto',
        marginTop: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    container: {

    }
  }));

const UploadBlog=(props)=>{
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [errorOpen, setErrorOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleErrorClose =(event, reason) => {
    if (reason === 'clickaway') {
        return;
      }
  
      setErrorOpen(false);
  }

    const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
    );

    const[heading, setHeading] = React.useState('');

    const [imageFiles, setImageFiles] = React.useState(null);

    const handleChange = (event) => {
        setHeading(event.target.value);
    };

    useEffect(() => {
        console.log(convertToRaw(editorState.getCurrentContent()));
    }, [editorState]);

    const handelFileChange = (e) => {
        console.log('hit');
        let fileArray = [];
        console.log(fileArray);
        Object.entries(e.target.files).map((file) => {
            fileArray.push(file[1]);
        });
        console.log(fileArray);
        setImageFiles(fileArray);
    };

    const handleUpload=(e)=>{
        e.preventDefault();
        console.log(heading)
        let formData = new FormData();
        formData.append('title', heading);
        for (let i = 0; i < imageFiles.length; i++) {
            formData.append('photo', imageFiles[i])
        }
        formData.append('content',JSON.stringify(convertToRaw(editorState.getCurrentContent())));

        updateBlog(formData).then((response) => {
            setHeading('');
            setImageFiles(null);
            setEditorState(EditorState.createEmpty())
            setOpen(true);

        })
        .catch((error) => {
            setHeading('');
            setImageFiles(null);
            setEditorState(EditorState.createEmpty())
            setErrorOpen(true);
        });

        // let saveBlogUrl = serverConstants.serverUrl + 'saveBlog';
        // const header = {
        //     Authorization: sessionStorage.getItem('epanipuriToken'),
        // };
        // axios
        //     .post(saveBlogUrl, formData, { headers: header })
        //     .then((response) => {
        //         console.log(response);
        //     })
        //     .catch((error) => {
        //         alert('Something went wrong!! Please try again.');
        // });
    }

    return (
        <Container maxWidth="lg" className={classes.container}>
        <DashboardNavbar />
        <ThemeProvider theme={Theme}>
        <div
            className={classes.paper}
            elevation={3}
            style={{ marginTop: '8rem' }}
        >
            <FormControl className={classes.margin} style={{width: '80%'}}> 
                <InputLabel htmlFor="input-with-icon-adornment">Heading</InputLabel>
                <Input
                id="input-with-icon-adornment"
                startAdornment={
                    <InputAdornment position="start">
                    <CreateIcon />
                    </InputAdornment>
                }
                value={heading}
                onChange={handleChange}
                />
            </FormControl>
            <br/>
            <FormControl className={classes.margin} style={{width: '80%'}}>
                    <Input
                    endAdornment={
                        <InputAdornment position="end">
                        <ImageIcon />
                        </InputAdornment>
                    }
                    type="file"
                    required={true}
                    inputProps={{ accept: 'image/*' }}
                    onChange={handelFileChange}
                    >
                    </Input>
            </FormControl>
            <br/>
            <Box style={{ border: "1px solid black", padding: '2px', minHeight: '400px', width: '80%' }} boxShadow={8}>
                <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                />
            </Box>
            <br/>
            <Button variant="contained" onClick={handleUpload} color="primary">
                Submit
            </Button>
            <br/>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleClose} severity="success">
                    Submitted the blog successfully!
                </Alert>
            </Snackbar>
            <Snackbar errorOpen={open} autoHideDuration={6000} onClose={handleErrorClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleErrorClose} severity="error">
                    Something went wrong
                </Alert>
            </Snackbar>
        </div>
        </ThemeProvider>
        </Container>
    );
}


export default (UploadBlog);