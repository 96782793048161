import * as actionTypes from '../actions/actionTypes';

const initialState = {
    user : null,
    showExpiredPopup: false
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.LOG_IN: 
        console.log('action.payload',action.payload);
            sessionStorage.setItem('lotteryUser', JSON.stringify(action.payload));
            return {
                ...state,
                user: action.payload
            }
        case actionTypes.LOG_OUT:
            sessionStorage.removeItem('epanipuriToken');
            sessionStorage.removeItem('lotteryUserId');
            sessionStorage.removeItem('epanipuriExpirationDate');
            sessionStorage.removeItem('lotteryUser');
            return {
                ...state,
                user: null,
                showExpiredPopup: false,
                apiError: {}
            }
        case actionTypes.API_ERROR:
            let showExpiredPopup = false;
            if (action.payload?.response?.status === 401) {
                showExpiredPopup = true;
            }
            return {
                ...state,
                apiError: action.payload,
                showExpiredPopup
            }
        default:
            return state;
    }
};

export default reducer;