import React from 'react';
import { connect } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { ThemeProvider } from '@material-ui/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DashboardNavbar from './DashboardNavbar';
import '../hoc/UI/orders.css';
import 'axios';
import Theme from '../hoc/UI/Theme';
import {
  getPendingForms,
  acceptRejectForm,
  rejectForm,
} from '../api/approveFormApi';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import axios from 'axios';
import constants from '../assets/data/serverConstants';
import history from "../hoc/utils/history";

const styles = (theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing.unit,
  },
});
class ApproveForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: 'Users',
      isPopupOpened: false,
      selectedFormId: undefined,
      selectedActionName: undefined,
      isDetailsPopupOpened: false,
      formDetails: [],
      fullWidth: true,
      maxWidth: 'sm',
      location: '',
      isApproving: false,
      selected: 'in_review',
      popup: false,
      balance: "",
      Loading: false,
      userId: '',
      userList: [],
      showAddUser: false,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      username: ""
    };
  }
  componentDidMount() {
    // this.props.getPendingForms(this.state.selected);
    this.getUsers()
  }

  getUsers = () => {
    axios
      .get(constants.baseURL + "/api/users", { headers: { "Content-Type": "application/json", 'ngrok-skip-browser-warning': 'true' } })
      .then((response) => {
        // setLoading(false); 
        console.log(' CHECK USERS RESPONSE ', response.data.data);
        let userList = response.data.data;
        if (userList.length > 0) {
          userList = userList.filter(a=> a.userType == 'user')
          userList.map(a => {

            let newData = a;
            newData['action'] = this.getActionButton(a);
            return newData
          })
        }
        this.setState({ userList: userList })
        // props.login(response.data.data);
        // history.push('/home');
      })
      .catch((error) => {
        console.log('error user', error);
        // setLoading(false);
        // alert("User not found")
        // sessionStorage.removeItem('epanipuriToken');
        // sessionStorage.removeItem('lotteryUserId');
        // sessionStorage.removeItem('epanipuriExpirationDate');
      });
  }

  getColumns = () => {
    return [
      // 'Title',
      {
        name: 'first_name',
        label: "First Name"
      },
      {
        name: 'last_name',
        label: "Last Name"
      },
      {
        name: 'email',
        label: "Email"
      },
      {
        name: 'phone',
        label: "mobile"
      },
      {
        name: 'walletBalance',
        label: "Wallet"
      },
      {
        name: 'mainBalance',
        label: "Main"
      },
      // {
      //   name:'createdAt',
      //   label:"createdDate"
      // },
      {
        name: 'action',
        label: "Action"
      },
    ];
  };

  getActionButton = (item) => {
    const isShowAcceptReject = this.state.selected == 'in_review'
    return (
      <React.Fragment>
        <Button
          style={{ marginRight: '20px' }}
          onClick={() => {
            // this.onActionClicked(data, 'View');
            this.setState({ popup: true, userId: item._id })
          }}
          variant="contained"
        >
          Withdraw
        </Button>
        <Button
          onClick={() => {
            // this.onActionClicked(formId, 'Approve');
            console.log('data', item);
            history.push("/balanceHistory", { data: item._id })
            // history.push("/orderDetail",{data:item._id})
          }}
          variant="contained"
          color="primary"
        >
          Transactions
        </Button>
        {/* {isShowAcceptReject ? <Button
          onClick={() => {
            this.onActionClicked(formId, 'Approve');
          }}
          variant="contained"
          color="primary"
        >
          Approve
        </Button> : null}
        {isShowAcceptReject ? <Button
          style={{ marginLeft: '20px' }}
          onClick={() => {
            this.onActionClicked(formId, 'Reject');
          }}
          variant="contained"
          color="primary"
          className="btn-danger"
        >
          Reject
        </Button>: null} */}
      </React.Fragment>
    );
  };

  getDate = (date) => {
    let newDate = new Date(date);
    return newDate.toDateString();
  };

  handleClose = () => {
    this.setState({
      isPopupOpened: false,
      selectedFormId: undefined,
      selectedActionName: undefined,
    });
  };

  handleDetailsClose = () => {
    this.setState({
      isDetailsPopupOpened: false,
      formDetails: [],
    });
  };

  onActionClicked = (id, Action) => {
    if (Action === 'View') {
      let details = [];
      details.push(id);

      this.setState(
        {
          formDetails: details,
        },
        () => {
          this.setState({
            isDetailsPopupOpened: true,
          });
        }
      );
    } else {
      this.setState(
        {
          selectedFormId: id,
          selectedActionName: Action,
        },
        () => {
          this.setState({
            isPopupOpened: true,
          });
        }
      );
    }
  };

  getPopup = () => {
    return (
      <Dialog
        open={this.state.isPopupOpened}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent className="dialog-content">
          Are you sure you want to <b>{this.state.selectedActionName}</b> ?
          {this.state.selectedActionName === 'Approve' ? (
            <TextField
              label="Location"
              type="text"
              name="quantity"
              value={this.state.location}
              margin="dense"
              fullWidth
              required={true}
              onChange={this.handleChange}
            ></TextField>
          ) : (
            ''
          )}
          <Grid container spacing={3} style={{ paddingTop: '20px' }}>
            <Grid item xs={12} sm={6}>
              <Button
                onClick={this.handleAcceptRejectForm}
                variant="contained"
                color="primary"
                disabled={
                  this.state.selectedActionName === 'Approve' &&
                  (this.state.location?.trim() === '' ||
                    this.state.location?.trim() === null)
                }
              >
                {this.state.isApproving ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  'Yes'
                )}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                className="reject-button"
                onClick={this.handleClose}
                variant="contained"
              >
                No
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    );
  };

  maskAddhar = (no) => {
    return no.replace(/\d(?=\d{2})/g, 'X');
  };

  handleDropDownChange = (event) => {
    this.setState(
      {
        ...this.state,
        selected: event.target.value,
      },
      () => {
        this.props.getPendingForms(this.state.selected);
        console.log(this.state.selected);
      }
    );


  };

  getDetailsPopup = () => {
    return (
      <Dialog
        open={this.state.isDetailsPopupOpened}
        onClose={this.handleDetailsClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={this.state.fullWidth}
        maxWidth={this.state.maxWidth}
      >
        <DialogTitle id="alert-dialog-title">Information</DialogTitle>
        <DialogContent className="dialog-content">
          <Grid container spacing={6} style={{ paddingTop: '20px' }}>
            <Grid item xs={12}>
              <Paper style={{ maxHeight: '360px', overflowY: 'auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Details</b>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.formDetails.map((row, index) => (
                      <React.Fragment>
                        {/* <TableRow key="1_1">
                          <TableCell component="th" scope="row">
                            Father Name
                          </TableCell>
                          <TableCell align="right">{row.fatherName}</TableCell>
                        </TableRow> */}
                        <TableRow key="1_1">
                          <TableCell component="th" scope="row">
                            Franchise Type
                          </TableCell>
                          <TableCell align="right">
                            {row.franchise_type ? row.franchise_type : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow key="1_1_1">
                          <TableCell component="th" scope="row">
                            Master Franchise Category
                          </TableCell>
                          <TableCell align="right">
                            {row.master_franchise_category
                              ? row.master_franchise_category
                              : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow key="1_1_2">
                          <TableCell component="th" scope="row">
                            GSTIN
                          </TableCell>
                          <TableCell align="right">
                            {row.gst_no ? row.gst_no : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow key="1_1_33">
                          <TableCell component="th" scope="row">
                            Entity Type
                          </TableCell>
                          <TableCell align="right">
                            {row.gst_treatment ? row.gst_treatment : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow key="1_1_3">
                          <TableCell component="th" scope="row">
                            Billing Adress
                          </TableCell>
                          <TableCell align="right">
                            {row.billing_address} {row.billing_pincode}
                          </TableCell>
                        </TableRow>
                        <TableRow key="1_1_4">
                          <TableCell component="th" scope="row">
                            Shipping Adress
                          </TableCell>
                          <TableCell align="right">
                            {row.shipping_address} {row.shipping_pincode}
                          </TableCell>
                        </TableRow>

                        <TableRow key="1_2">
                          <TableCell component="th" scope="row">
                            Location
                          </TableCell>
                          <TableCell align="right">
                            {row.location ? row.location : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow key="1_3_44">
                          <TableCell component="th" scope="row">
                            State
                          </TableCell>
                          <TableCell align="right">
                            {row.state ? row.state : '-'}
                          </TableCell>
                        </TableRow>

                        <TableRow key="1_1_5">
                          <TableCell component="th" scope="row">
                            Model Name
                          </TableCell>
                          <TableCell align="right">
                            {row.model_name ? row.model_name : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow key="1_1_6">
                          <TableCell component="th" scope="row">
                            Extension
                          </TableCell>
                          <TableCell align="right">
                            {row.model_extension ? row.model_extension : '-'}
                          </TableCell>
                        </TableRow>
                        {/* <TableRow key="1_4">
                          <TableCell component="th" scope="row">
                          selectedTowns
                          </TableCell>
                          <TableCell align="right">
                          {row.selectedTowns.map((item, index) => (
                              <ul style={{listStyleType:"none"}}><li>{item}</li></ul>
                          ))}
                          </TableCell>
                        </TableRow> */}
                        <TableRow key="1_4">
                          <TableCell component="th" scope="row">
                            Price
                          </TableCell>
                          <TableCell align="right">
                            {row.price ? row.price : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow key="1_5">
                          <TableCell component="th" scope="row">
                            Addhar Number
                          </TableCell>
                          <TableCell align="right">
                            {this.maskAddhar(row.aadhar)
                              ? this.maskAddhar(row.aadhar)
                              : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow key="1_6">
                          <TableCell component="th" scope="row">
                            Annual Target
                          </TableCell>
                          <TableCell align="right">
                            {row.annual_target ? row.annual_target : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow key="1_7">
                          <TableCell component="th" scope="row">
                            Monthly Target
                          </TableCell>
                          <TableCell align="right">
                            {row.monthly_target ? row.monthly_target : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow key="1_8">
                          <TableCell component="th" scope="row">
                            Purchase Type
                          </TableCell>
                          <TableCell align="right">
                            {row.purchase_type ? row.purchase_type : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow key="1_9">
                          <TableCell component="th" scope="row">
                            Town
                          </TableCell>
                          <TableCell align="right">
                            {row.town ? row.town : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow key="1_10">
                          <TableCell component="th" scope="row">
                            Trade Name
                          </TableCell>
                          <TableCell align="right">
                            {row.trade_name ? row.trade_name : '-'}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                className="reject-button"
                onClick={this.handleDetailsClose}
                variant="contained"
                color="primary"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    );
  };
  createData(...data) {
    return { ...data };
  }

  handleAcceptRejectForm = () => {
    if (this.state.selectedActionName === 'Approve') {
      this.setState({ ...this.state, isApproving: true });
      const payload = {
        formId: this.state.selectedFormId,
        location: this.state.location,
      };
      this.props.acceptRejectForm(payload).then(() => {
        this.props.getPendingForms(this.state.selected);
        this.setState({
          isPopupOpened: false,
          selectedBlogId: undefined,
          selectedBlogName: undefined,
          location: '',
          isApproving: false,
        });
      });
    } else {
      const payload = {
        formId: this.state.selectedFormId,
      };
      this.props.rejectForm(payload).then(() => {
        this.props.getPendingForms(this.state.selected);
        this.setState({
          isPopupOpened: false,
          selectedBlogId: undefined,
          selectedBlogName: undefined,
        });
      });
    }
  };

  getBlogsToShow = () => {
    let forms = [];
    forms = this.props.forms.map((form) => {
      return [
        form.title,
        form.firstName,
        form.lastName,
        // form.address,
        form.email,
        // form.franchise_type,
        // form.gst_no,
        // form.gst_treatment,
        form.mobile,
        this.getDate(form.createdDate),
        this.getActionButton(form.formId, form),
        form.isMulti,
        form.isSubscription,
        form.fatherName,
        form.formId,
        form.termsAndConditions,
        form.state,
        form.status,
        form.location,
        form.pincode,
        form.selectedTowns,
      ];
    });

    return forms;
  };

  handleChange = (event) => {
    this.setState({
      ...this.state,
      location: event.target.value,
    });
    // this.props.getPendingForms(this.state.selected);
  }

  getSelector() {
    return (
      <div className="display">
        <FormControl margin="normal" fullWidth>
          <InputLabel id="action-status">Status</InputLabel>
          <Select
            labelId="action-status"
            id="status"
            value={this.state.selected}
            label="Status"
            defaultValue="in_review"
            onChange={this.handleDropDownChange}
          >
            <MenuItem value="in_review">In Review</MenuItem>
            <MenuItem value="accepted">Accepted</MenuItem>
            <MenuItem value="rejected">Rejected</MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  }

  checkValidation = () => {
    if (this.state.userId == '') {
      alert("User not selected")
      return false
    } else if (this.state.balance == '') {
      alert("Please enter amount")
      return false
    }
    // else if (values.startTime == '') {
    //     alert("Please enter start time")
    //     return false
    // }else if (values.endTime == '') {
    //     alert("Please enter end time")
    //     return false
    // }
    // else if (values.totalPage == '') {
    //     alert("Please enter total page")
    //     return false
    // }
    return true
  }

  onSaveData = () => {
    this.setState({ Loading: true })
    if (!this.checkValidation()) {
      return
    }

    const addBalanceData = {
      "userId": this.state.userId,
      "mainBalance": Number(this.state.balance),
      // "endTime":values.endTime
    }

    let addBalanceURL = constants.baseURL + '/api/wallet/withdrawBalance';
    const headers = { "Content-Type": "application/json", 'ngrok-skip-browser-warning': 'true' };
    return axios.post(addBalanceURL, addBalanceData, { headers: headers }).then(response => {
      console.log('response', response.data);
      // setGameList(response.data.data);
      if (response.data.status != 200) {
        alert(response.data.message)
      }
      this.setState({ Loading: false, balance: '' })
      this.getUsers()
      // alert("Balance data added successfully!")
      this.closePopup()
    }).catch(error => {
      console.log(' ERROR IN WITHDRAW ', error);
      alert("Something went wrong, please try again!")
      this.setState({ Loading: false, balance: '' })
      this.closePopup()
      // alert("No data found")
    })
  }

  checkValidationAddUser = () => {
    if (this.state.firstName == '') {
      alert("Please enter first name")
      return false
    } else if (this.state.lastName == '') {
      alert("Please enter last name")
      return false
    } else if (this.state.email == '') {
      alert("Please enter email")
      return false
    } else if (this.state.phone == '') {
      alert("Please enter phone")
      return false
    } else if (this.state.password == '') {
      alert("Please enter password")
      return false
    }
    return true
  }
  saveUser = () => {
    this.setState({ Loading: true })
    if (!this.checkValidationAddUser()) {
      return
    }

    const addBalanceData = {
      "first_name": this.state.firstName,
      "last_name": this.state.lastName,
      "email": this.state.email,
      "phone": this.state.phone,
      "password": this.state.password,
      "username":this.state.username
    }

    let addBalanceURL = constants.baseURL + '/api/register';
    const headers = { "Content-Type": "application/json", 'ngrok-skip-browser-warning': 'true' };
    return axios.post(addBalanceURL, addBalanceData, { headers: headers }).then(response => {
      console.log('response', response.data);
      // setGameList(response.data.data);
      if (response.data.status != 200) {
        alert(response.data.message)
      }
      this.setState({ Loading: false, balance: '',showAddUser:false })
      this.getUsers()
      alert("User added successfully!")
      // this.closePopup()
    }).catch(error => {
      console.log(' ERROR IN WITHDRAW ', error);
      alert("Something went wrong, please try again!")
      this.setState({ Loading: false, balance: '' })
      this.closePopup()
      // alert("No data found")
    })
  }

  closePopup = () => {
    this.setState({ popup: false, userId: '',showAddUser:false })
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }
  render() {
    const { classes } = this.props;
    const options = {
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
      selectableRows: 'none',
    };

    return (
      <React.Fragment>
        <ThemeProvider theme={Theme}>
          <DashboardNavbar />
          <br />
          <br />
          <br />
          {/* {this.getSelector()} */}

          <div id="blogTable">
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={() => {
                  // openPopup();
                  this.setState({showAddUser:true})
                }}
              >
                {' '}
                Add New User
              </Button>
            </div>
            <MUIDataTable
              title={this.state.pageTitle}
              data={this.state.userList}
              columns={this.getColumns()}
              options={options}
            />
            {this.getPopup()}
            {this.getDetailsPopup()}
          </div>
        </ThemeProvider>
        <Dialog
          open={this.state.popup}
          onClose={this.closePopup}
          aria-labelledby="form-dialog-title" >
          <DialogTitle id="form-dialog-title">
            Withdraw Balance
          </DialogTitle>
          <DialogContent>
            <TextField
              label="Amount"
              type="Number"
              name="walletBalance"
              value={this.state.balance}
              margin="dense"
              fullWidth
              // multiline
              // rowsMax={10}
              required={true}
              onChange={(event) => {
                this.setState({ balance: event.target.value })
              }}
            />

          </DialogContent>
          <DialogActions>
            {this.state.Loading ? (
              <CircularProgress />
            ) : (
              <ThemeProvider theme={Theme}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={this.closePopup}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={this.onSaveData}
                >
                  Save
                </Button>
              </ThemeProvider>
            )}
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.showAddUser}
          onClose={() => this.setState({ showAddUser: false })}
          aria-labelledby="form-dialog-title" >
          <DialogTitle id="form-dialog-title">
            Add User
          </DialogTitle>
          <DialogContent>
            <TextField
              label="First Name"
              type="text"
              name="firstName"
              value={this.state.firstName}
              margin="dense"
              fullWidth
              multiline
              rowsMax={10}
              required={true}
              onChange={this.handleChange}
            />
            <TextField
              label="Last Name"
              type="text"
              name="lastName"
              value={this.state.lastName}
              margin="dense"
              fullWidth
              required={true}
              onChange={this.handleChange}
            />
            <TextField
              label="Email"
              type="text"
              name="email"
              value={this.state.email}
              margin="dense"
              fullWidth
              required={true}
              onChange={this.handleChange}
            />
            <TextField
              label="Phone No."
              type="number"
              name="phone"
              value={this.state.phone}
              margin="dense"
              maxRows={10}
              fullWidth
              required={true}
              onChange={this.handleChange}
            />
            <TextField
              label="User name"
              type="text"
              name="username"
              value={this.state.username}
              margin="dense"
              maxRows={10}
              fullWidth
              required={true}
              onChange={this.handleChange}
            />
            <TextField
              label="Password"
              type="password"
              name="password"
              value={this.state.password}
              margin="dense"
              fullWidth
              required={true}
              onChange={this.handleChange}
            />
          </DialogContent>
          <DialogActions>
            {this.state.Loading ? (
              <CircularProgress />
            ) : (
              <ThemeProvider theme={Theme}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={() => this.setState({ showAddUser: false })}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={this.saveUser}
                >
                  Save
                </Button>
              </ThemeProvider>
            )}
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ApproveForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    forms: { forms },
  } = state;
  return {
    user: state.auth.user
      ? state.auth.user
      : JSON.parse(sessionStorage.getItem('lotteryUser')),
    orders: state.orders,
    costings: state.costings,
    blogs: state.blogs,
    forms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPendingForms: (condition) => {
      getPendingForms(dispatch, condition);
    },
    acceptRejectForm: (payload) => {
      return acceptRejectForm(payload);
    },
    rejectForm: (payload) => {
      return rejectForm(payload);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ApproveForm));
