import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import history from "../hoc/utils/history";

const SessionExpired = (props) => {
    const handleLogout = () => {
        props.logout();
        history.push("/");
      };
        return (
            <div>
                <Dialog
                    open={true}
                    onClose={handleLogout}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Your session has been expired</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Your session has been expired. Please login again to continue.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleLogout} color="primary" autoFocus>
                        Login
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
};

export default SessionExpired;
