import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { ThemeProvider } from '@material-ui/styles';
import epanipuricart from '../assets/images/epanipuricart.png';
import Theme from '../hoc/UI/Theme';
import Styles from '../hoc/UI/Styles';

const LoginNavbar = () => {

  const classes = Styles();

  return (
    <ThemeProvider theme={Theme}>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <img
              src={epanipuricart}
              alt="logo"
              width="50"
              height="50"
              className={classes.logo}
            />
            <Typography variant="h6" className={classes.title}>
              Jagaran Pustika Online Marketing
          </Typography>
          </Toolbar>
        </AppBar>
      </div>
    </ThemeProvider>
  );
};

export default LoginNavbar;
