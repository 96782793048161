import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DashboardNavbar from './DashboardNavbar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Theme from '../hoc/UI/Theme';
import { ThemeProvider } from '@material-ui/styles';
import serverConstants from '../assets/data/serverConstants';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getPricing, updatePricing } from '../api/OrderApi';
import SearchBar from 'material-ui-search-bar';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  paper: {
    width: '90%',
    margin: 'auto'
  },
});

const Pricing = (props) => {
  const classes = useStyles();

  const [pricingData, setPricingData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [filterValue, setFilterValue] = useState('');
  const [popup, setPopup] = React.useState(false);
  const [values, setValues] = useState({
    uid: '',
    name: '',
    price: '' 
  });
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    getPricingData();
  }, []);

  const getPricingData = () => {
    getPricing().then((response) => {
      console.log(response)
        response.data.items.forEach((item) => {
          item.dateModified = new Date(item.dateModified).toLocaleString();
        });
        setPricingData(response.data);
        setFilteredData(response.data);
    })

  };

  const filterData = (filterValue) => {
    const filteredResult = {items: []};
    pricingData.items.forEach((row) => {
      if(row.name.toLowerCase().includes(filterValue.toLowerCase())) {
        filteredResult.items.push(row);
      } 
    });
    setFilteredData(filteredResult); 
  };

  const cancelFilter = () => {
    setFilterValue('');
    filterData(filterValue);
  };

  const openPopup = (uid, name, price) => {
    setPopup(true);
    setLoading(false);
    setValues({...values, name:name, price:price, uid:uid})
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const updateData = () => {
    setLoading(true);
    const updateData = {
      uid: values.uid,
      name: values.name,
      price: values.price
    };

    updatePricing(updateData).then((response) => {
          setPopup(false);
          getPricingData();
      })
      .catch((error) => {
          alert('Something went wrong!! Please try again.');
          setPopup(false);
      });
  }

  const closePopup = () => {
    setPopup(false);
  }

  return (
    <React.Fragment>
      <DashboardNavbar />
      <br />
      <br />
      <br />

      <Paper
        className={classes.paper}
        elevation={3}
        // spacing={30}
        // mt={30}
        // direction="column"
        // alignItems="center"
        // justify="center"
        style={{ marginTop: '2rem' }}
      >
        <SearchBar
          value={filterValue}
          placeholder="Filter By Model Name"
          onChange={(filterValue) => filterData(filterValue)}
          onCancelSearch={cancelFilter}
        />
        <Table
          className={classes.table}
          size="small"
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <th>Sl.No</th>
              </TableCell>
              <TableCell align="left">
                <th>Model name</th>
              </TableCell>
              <TableCell align="left">
                <th>Extension</th>
              </TableCell>
              <TableCell align="left">
                <th>Last Modified</th>
              </TableCell>
              <TableCell align="left">
                <th>Pricing in Rupee</th>
              </TableCell>
              <TableCell align="left">
                <th>Modify</th>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData &&
              filteredData.items.map((row, index) => (
                <TableRow key={row.uid}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.extension}</TableCell>
                  <TableCell align="left">{row.dateModified}</TableCell>
                  <TableCell align="left">{row.price}</TableCell>
                  <TableCell align="left">
                    <ThemeProvider theme={Theme}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        onClick={() => openPopup(row.uid,row.name,row.price)}
                      >
                        {' '}
                        Update
                      </Button>
                    </ThemeProvider>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={popup}
        onClose={() => setPopup(false)}
        aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">
                Update Pricing
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Model Name"
            type="text"
            name="name"
            value={values.name}
            margin="dense"
            fullWidth
            multiline
            rowsMax={10}
            onChange={handleChange}
          />
          <TextField
            label="Pricing"
            type="number"
            name="price"
            value={values.price}
            margin="dense"
            fullWidth
            required={true}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          {Loading ? (
            <CircularProgress />
          ) : (
            <ThemeProvider theme={Theme}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={closePopup}
              >
                Cancel
          </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={updateData}
              >
                Update
          </Button>
            </ThemeProvider>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
      ? state.auth.user
      : JSON.parse(sessionStorage.getItem('lotteryUser')),
  };
};

export default connect(mapStateToProps)(Pricing);
