import * as actionTypes from '../actions/actionTypes';

const initialState = {
    stocks: []
};

const stocksReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_STOCKS:
            return {
                ...state,
                stocks: action.payload
            }
        default:
            return state;
    }
};

export default stocksReducer;