import React from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import DashboardNavbar from "./DashboardNavbar";
import Styles from "../hoc/UI/Styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Theme from "../hoc/UI/Theme";
import { ThemeProvider } from "@material-ui/styles";
import {
  getStocks,
  updateStock,
  decreaseStock,
  createStock,getLogs
} from "../api/manageStock";
import { CardHeader } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import "../hoc/UI/orders.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MUIDataTable from 'mui-datatables';

const columns = [
  'Name',
  'Quantity',
  {
    name: "Action",
    options: {
      filter: true,
      customBodyRender: value => {
        return value === 'Removed' ? <span style={{color:'red'}}> {value}</span>: <span style={{color:'green'}} > {value}</span>;
      }
    }
  },
  'Email',
  'Date'
];
const options = {
  filter: true,
  download: false,
  print: false,
  viewColumns: false,
  selectableRows: 'none',
};
class ManageStock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Manage Stocks",
      popup: false,
      quantity: 0,
      stockId: "",
      operation: "",
      createPopup: false,
      createObj: {
        name: "",
        description: "",
        price: 0,
        quantity: 0,
      },
      tabIndex:0,
      addedchecked:true,
      removedchecked:true,
    };
  }
  componentDidMount() {
    this.props.getAllStocks();
  }

  handleClickRemove(stockId, operation) {
    this.setPopup(true, stockId, operation);
  }
  setPopup(isOpen, stockId, operation) {
    this.setState({
      ...this.state,
      popup: isOpen,
      stockId: stockId,
      operation,
    });
  }
  closePopup() {
    this.setPopup(false, "", "");
  }

  setCreatePopUp(isCreateOpen) {
    this.setState({
      ...this.state,
      createPopup: isCreateOpen,
    });
  }

  handleChange = (event) => {
    this.setState({
      ...this.state,
      quantity: event.target.value,
    });
  };
  handleUpdate() {
    if (this.state.operation === "ADD") {
      this.props
        .increaseStockStatus(this.state.quantity, this.state.stockId)
        .then(() => {
          this.setState({
            ...this.state,
            popup: false,
            quantity: 0,
            stockId: "",
            operation: "",
          });
          this.props.getAllStocks();
        });
    } else {
      this.props
        .decreaseStockStatus(this.state.quantity, this.state.stockId)
        .then(() => {
          this.setState({
            ...this.state,
            popup: false,
            quantity: 0,
            stockId: "",
            operation: "",
          });
          this.props.getAllStocks();
        });
    }
  }

  handleClickCreate() {
    this.setCreatePopUp(true);
  }

  handleCreateChange = (event, field) => {
    if (field === "name") {
      let name = { ...this.state };
      name.createObj.name = event.target.value;
      this.setState(name);
    }
    if (field === "des") {
      let description = { ...this.state };
      description.createObj.description = event.target.value;
      this.setState(description);
    }
    if (field === "quantity") {
      let quantity = { ...this.state };
      quantity.createObj.quantity = event.target.value;
      this.setState(quantity);
    }
    if (field === "price") {
      let price = { ...this.state };
      price.createObj.price = event.target.value;
      this.setState(price);
    }
  };
  handleCreateUpdate() {
    this.props.createStockStatus(this.state.createObj).then(() => {
      this.setState({
        ...this.state,
        createPopup: false,
        createObj: {
          name: "",
          description: "",
          price: 0,
          quantity: 0,
        },
      });
      this.props.getAllStocks();
    });
  }
  onTabChange = (event, newValue) => {
    this.setState(
      {
        tabIndex: newValue,
      },
      () => {
        if (this.state.tabIndex === 0) {
          this.setState({
            title: "Manage Stocks",
          });
        } else if (this.state.tabIndex === 1) {
          this.props.getAllLogs();
          this.setState({
            title: "Stock Logs",
          });
        } 
      }
    );
  };
  getLogsToShow = () => {
    let logs = [];
    logs = this.props.logs?.map((log) => {
          return [
            log.details[0]?.name,
            log.quantity,
            log.type === 'inc' ? 'Added' : 'Removed',
            log.email,
            this.getDate(log.timestamp),
          ];
        });
    return logs;
  };

  getDate = (date) => {
    let newDate = new Date(date);
    return newDate?.toDateString() || '';
  };

  render() {
    return (
      <React.Fragment>
        <ThemeProvider theme={Theme}>
          <DashboardNavbar />
          <br />
          <br />
          <br />
          <br />

          <div id="manageStocks">
          <Tabs
              value={this.state.tabIndex}
              onChange={this.onTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="Manage Stocks" />
              <Tab label="Stock Logs" />
              
            </Tabs>
            { this.state.tabIndex === 0 ? <Card>
              {this.props.user?.roles[0] === "super" ? (
                <CardHeader
                  title={this.state.title}
                  action={
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => this.handleClickCreate("", "ADD")}
                    >
                      Create
                    </Button>
                  }
                ></CardHeader>
              ) : (
                <CardHeader title={this.state.title}></CardHeader>
              )}

              <Divider />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <List dense>
                    {this.props.stocks.map((value) => {
                      const labelId = `checkbox-list-secondary-label-${value?.stockId}`;
                      return (
                        <div>
                          <Grid container xs={12} md={12}>
                            <Grid item xs={12} md={3}>
                              <ListItem
                                key={labelId}
                                button
                                className="manageStockspadding"
                              >
                                <ListItemText
                                  id={labelId}
                                  primary={value?.name}
                                />
                              </ListItem>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <ListItem
                                key={value?.stockId}
                                button
                                className="manageStockspadding"
                              >
                                <ListItemText
                                  id={labelId}
                                  primary={value?.quantity}
                                />
                              </ListItem>
                            </Grid>
                            <Grid container xs={12} md={6}>
                              <Grid xs={6} md={3}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  startIcon={<AddIcon />}
                                  onClick={() =>
                                    this.handleClickRemove(
                                      value?.stockId,
                                      "ADD"
                                    )
                                  }
                                >
                                  Add Stocks
                                </Button>
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  startIcon={<DeleteIcon />}
                                  onClick={() =>
                                    this.handleClickRemove(
                                      value?.stockId,
                                      "REMOVE"
                                    )
                                  }
                                >
                                  Remove Stocks
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider />
                        </div>
                      );
                    })}
                  </List>
                </Grid>
              </Grid>
            </Card> : 
        
            <MUIDataTable
              title={this.state.title}
              data={this.getLogsToShow()}
              columns={columns}
              options={options}
              
            />
           }
            
          </div>

          <Dialog
            open={this.state.popup}
            onClose={() => this.setPopup(false, "")}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {this.state.operation === "ADD" ? "Add stocks" : "Remove stocks"}
            </DialogTitle>
            <DialogContent>
              <TextField
                label="Quantity"
                type="number"
                name="quantity"
                value={this.state.quantity}
                margin="dense"
                fullWidth
                required={true}
                onChange={this.handleChange}
                InputProps={{ inputProps: { min: 0 } }}
              ></TextField>
            </DialogContent>
            <DialogActions>
              <ThemeProvider theme={Theme}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => this.closePopup()}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  // className={classes.button}
                  onClick={() => this.handleUpdate()}
                >
                  Update
                </Button>
              </ThemeProvider>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.createPopup}
            onClose={() => this.setCreatePopUp(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Create stocks
            </DialogTitle>
            <DialogContent>
              <TextField
                label="name"
                type="test"
                name="name"
                value={this.state.createObj?.name}
                margin="dense"
                fullWidth
                required={true}
                onChange={(e) => this.handleCreateChange(e, "name")}
              ></TextField>
              <TextField
                id="standard-multiline-flexible"
                label="Description"
                multiline
                maxRows={4}
                fullWidth
                value={this.state.createObj?.description}
                onChange={(e) => this.handleCreateChange(e, "des")}
              />
              <TextField
                label="price"
                type="number"
                name="price"
                value={this.state.createObj?.price}
                margin="dense"
                fullWidth
                required={true}
                onChange={(e) => this.handleCreateChange(e, "price")}
                InputProps={{ inputProps: { min: 0 } }}
              ></TextField>
              <TextField
                label="Quantity"
                type="number"
                name="quantity"
                value={this.state.createObj?.quantity}
                margin="dense"
                fullWidth
                required={true}
                onChange={(e) => this.handleCreateChange(e, "quantity")}
                InputProps={{ inputProps: { min: 0 } }}
              ></TextField>
            </DialogContent>
            <DialogActions>
              <ThemeProvider theme={Theme}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => this.setCreatePopUp(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  // className={classes.button}
                  onClick={() => this.handleCreateUpdate()}
                >
                  Update
                </Button>
              </ThemeProvider>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    stocks: { stocks },
    logs:{logs}
  } = state;
  return {
    user: state.auth.user
      ? state.auth.user
      : JSON.parse(sessionStorage.getItem("lotteryUser")),
    orders: state.orders,
    costings: state.costings,
    blogs: state.blogs,
    stocks,
    logs
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllStocks: () => {
      getStocks(dispatch);
    },
    increaseStockStatus: (quantity, stockId) => {
      return updateStock(quantity, stockId);
    },
    decreaseStockStatus: (quantity, stockId) => {
      return decreaseStock(quantity, stockId);
    },
    createStockStatus: (payload) => {
      return createStock(payload);
    },
    getAllLogs:()=>{
      getLogs(dispatch);
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageStock);
