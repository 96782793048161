import React from 'react';
import { connect } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { ThemeProvider } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import PublishIcon from '@material-ui/icons/Publish';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DashboardNavbar from './DashboardNavbar';
import '../hoc/UI/orders.css';
import { Input, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import 'axios';
import Theme from '../hoc/UI/Theme';
import {
  getOrders,
  getCosting,
  updateOrder,
  updateUpload,
  updateOngoingOrder,
} from '../api/OrderApi';
import Button from '@material-ui/core/Button';



const columns = [
  'Order ID',
  'Name',
  'Email',
  'Amount Paid',
  'Model',
  'Date',
  'Action',
];
const options = {
  filter: false,
  download: false,
  print: false,
  viewColumns: false,
  selectableRows: 'none',
};
class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searched: '',
      orderStatus: 'New Orders',
      tabIndex: 0,
      isPopupOpened: false,
      isUploadPopupOpened: false,
      isOngoingPopupOpened: false,
      selectedOrderId: undefined,
      selectedOrderStatus: undefined,
      acceptanceDate: undefined,
      orderFiles: undefined,
      deliveryState: 'packed',
      open: false,
      snackOpen: false,
      onGoingStatus: '',
    };
  }
  componentDidMount() {
    this.props.getAllOrders();
    this.props.getAllCosting();
  }

  handleSnackOpen = () => this.setState({ snackOpen: true });
  handleSnackClose = () => this.setState({ snackOpen: false });

  onTabChange = (event, newValue) => {
    this.setState(
      {
        tabIndex: newValue,
      },
      () => {
        if (this.state.tabIndex === 0) {
          this.setState({
            orderStatus: 'New Orders',
          });
        } else if (this.state.tabIndex === 1) {
          this.setState({
            orderStatus: 'Ongoing Orders',
          });
        } else if (this.state.tabIndex === 2) {
          this.setState({
            orderStatus: 'Completed Orders',
          });
        } else {
          this.setState({
            orderStatus: 'Rejected Orders',
          });
        }
      }
    );
  };

  handleDropdownClose = () => {
    this.setState({
      open: false,
    });
  };

  handleDropdownOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleDropdownChange = (value) => {
    console.log(value);
    // this.setState({
    //   deliveryState: value,
    // });
    this.state.deliveryState = value;
    console.log(this.state.deliveryState);
  };

  onActionClicked = (id, status) => {
    this.setState(
      {
        selectedOrderId: id,
        selectedOrderStatus: status,
      },
      () => {
        this.setState({
          isPopupOpened: true,
        });
      }
    );
  };

  onOngoingActionClicked = (id, status) => {
    this.setState(
      {
        selectedOrderId: id,
        selectedOrderStatus: status,
      },
      () => {
        this.setState({
          isOngoingPopupOpened: true,
        });
      }
    );
  };

  getColumns = () => {
    if (this.state.orderStatus === 'New Orders') {
      return [
        'Order ID',
        'Name',
        'Email',
        'Amount Paid',
        'Model',
        'Date',
        'Action',
      ];
    } else if (this.state.orderStatus === 'Ongoing Orders') {
      return [
        'Order ID',
        'Name',
        'Email',
        'Amount Paid',
        'Model',
        'Date',
        'Status',
        'Agreement',
        'Action',
      ];
    } else if (this.state.orderStatus === 'Completed Orders') {
      return [
        'Order ID',
        'Name',
        'Email',
        'Amount Paid',
        'Model',
        'Date',
        'Status',
      ];
    } else if (this.state.orderStatus === 'Rejected Orders') {
      return [
        'Order ID',
        'Name',
        'Email',
        'Amount Paid',
        'Model',
        'Date',
        'Status',
      ];
    }
  };

  getActionButton = (orderId, status) => {
    return (
      <Button
        onClick={() => {
          this.onActionClicked(orderId, status);
        }}
        variant="contained"
        color="primary"
      >
        Take Action
      </Button>
    );
  };

  getOngoingActionButton = (orderId, status) => {
    return (
      <Button
        onClick={() => {
          this.onOngoingActionClicked(orderId, status);
        }}
        variant="contained"
        color="primary"
      >
        Take Action
      </Button>
    );
  };

  getDate = (date) => {
    let newDate = new Date(date);
    return newDate.toDateString();
  };

  getModelName = (id) => {
    const costing = this.props.costings.find((obj) => {
      return id === obj.uid;
    });
    return `${costing ? costing.name : 'Table Top'} ${costing ? costing.extension: '3 nozzle'}`;
  };

  handleClose = () => {
    this.setState({
      isPopupOpened: false,
      selectedOrderId: undefined,
      selectedOrderStatus: undefined,
      acceptanceDate: undefined,
    });
  };

  handleUploadClose = () => {
    this.setState({
      isUploadPopupOpened: false,
      selectedOrderId: undefined,
      selectedOrderStatus: undefined,
      orderFiles: undefined,
    });
  };

  handleOngoingClose = () => {
    this.setState({
      isOngoingPopupOpened: false,
      selectedOrderId: undefined,
      selectedOrderStatus: undefined,
      acceptanceDate: undefined,
    });
  };

  handleDateChange = (event) => {
    this.setState({
      acceptanceDate: event.target.value,
    });
  };

  handleOrderAccept = () => {
    const date = new Date(this.state.acceptanceDate).getTime();
    this.props
      .acceptOrder(this.state.selectedOrderId, 'confirmed', date)
      .then(() => {
        this.props.getAllOrders();
        this.setState({
          isPopupOpened: false,
          selectedOrderId: undefined,
          selectedOrderStatus: undefined,
          acceptanceDate: undefined,
        });
      });
  };

  getPopup = () => {
    return (
      <Dialog
        open={this.state.isPopupOpened}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent className="dialog-content">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Button
                disabled={!this.state.acceptanceDate}
                onClick={this.handleOrderAccept}
                variant="contained"
                color="primary"
              >
                Accept
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                className="reject-button"
                onClick={this.handleClose}
                variant="contained"
              >
                Reject
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="date"
                label="Approximate Delivery Date"
                type="date"
                onChange={this.handleDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
                className="date-pick"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    );
  };

  getUploadButton = (orderId, status) => {
    return (
      <Button
        onClick={() => {
          this.onUploadClicked(orderId, status);
        }}
        variant="contained"
        color="primary"
      >
        {/* <span > */}
        Upload <PublishIcon />
        {/* </span> */}
      </Button>
    );
  };

  onUploadClicked = (id, status) => {
    console.log('clicked');
    this.setState(
      {
        selectedOrderId: id,
        selectedOrderStatus: status,
      },
      () => {
        this.setState({
          isUploadPopupOpened: true,
        });
      }
    );
  };

  getUploadPopup = () => {
    return (
      <Dialog
        open={this.state.isUploadPopupOpened}
        onClose={this.handleUploadClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent className="dialog-content">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Input
                type="file"
                required={true}
                inputProps={{ multiple: true }}
                onChange={this.handelFileChange}
              ></Input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                onClick={this.sendUploadRequest}
                variant="contained"
                color="primary"
              >
                Upload
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                className="reject-button"
                onClick={this.handleUploadClose}
                variant="contained"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    );
  };

  getOngoingPopup = () => {
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={this.state.isOngoingPopupOpened}
        onClose={this.handleOngoingClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent className="dialog-content" style={{ height: '120px' }}>
          {/* <div style={{display: 'flex', flexDirection: "row", justifyContent: "flex-start"}}> */}
          <Grid container spacing={4}>
            <Grid item xs={8}>
              <InputLabel
                id="demo-controlled-open-select-label"
                style={{
                  width: '100%',
                }}
              >
                Delivery Status
              </InputLabel>
              <Select
                native
                style={{
                  width: '100%',
                  margin: '0 auto',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
                value={this.deliveryState}
                onChange={(event) =>
                  this.handleDropdownChange(event.target.value)
                }
                inputProps={{
                  name: 'delivery',
                  id: 'delivery-native-simple',
                }}
              >
                <option style={{ textAlign: 'center' }} value={'packed'}>
                  PACKED
                </option>
                <option value={'dispatched'}>DISPATCHED</option>
                <option value={'delivered'}>DELIVERED</option>
                <option value={'installed'}>INSTALLED</option>
                <option value={'rejected'}>FORCE REJECT</option>
              </Select>
              {/* </FormControl> */}
            </Grid>
            <Grid item xs={4}>
              <Button
                onClick={this.sendOngoingRequest}
                style={{ marginTop: '12px', width: '100%' }}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Grid>
            {/* </div> */}
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    );
  };

  handelFileChange = (e) => {
    console.log('hit');
    let fileArray = [];
    console.log(fileArray);
    Object.entries(e.target.files).map((file) => {
      fileArray.push(file[1]);
    });
    console.log(fileArray);
    this.setState({ orderFiles: fileArray });
  };

  sendUploadRequest = () => {
    //Make service call here with id and file
    let formData = new FormData();
    formData.append('orderId', this.state.selectedOrderId);
    for (let i = 0; i < this.state.orderFiles.length; i++) {
      formData.append('file', this.state.orderFiles[i]);
    }
    this.props.uploadFile(formData).then(() => {
      this.setState({
        isUploadPopupOpened: false,
        selectedOrderId: undefined,
        selectedOrderStatus: undefined,
        orderFiles: undefined,
        snackOpen: true
      });
      this.props.getAllOrders();
    });

    console.log(this.state.selectedOrderId);
    console.log(this.state.orderFiles);
    
  }

  sendOngoingRequest = () => {
    console.log(this.state.deliveryState);
    // if(this.state.deliveryState==="FORCE REJECT"){
    //     this.setState({
    //         onGoingStatus: "rejected"
    //     })
    // }else{
    this.props
      .uploadDeliveryStatus(
        this.state.selectedOrderId,
        this.state.deliveryState
      )
      .then(() => {
        this.setState({
          isOngoingPopupOpened: false,
          selectedOrderId: undefined,
          selectedOrderStatus: undefined,
        });
      });
  };

  getOrdersToShow = () => {
    let orders = [];
    if (this.state.orderStatus === 'New Orders') {
      orders = this.props.orders
        .filter((order) => {
          return order.status === 'placed';
        })
        .map((order) => {
          return [
            order.order_id,
            order.name,
            order.email,
            15000,
            this.getModelName(order.model_uid),
            this.getDate(order.date),
            this.getActionButton(order.order_id, order.status),
          ];
        });
    } else if (this.state.orderStatus === 'Ongoing Orders') {
      orders = this.props.orders
        .filter((order) => {
          if (
            order.status !== 'placed' &&
            order.status !== 'rejected' &&
            order.status !== 'installed'
          ) {
            return true;
          }
          return false;
        })
        .map((order) => {
          return [
            order.order_id,
            order.name,
            order.email,
            15000,
            this.getModelName(order.model_uid),
            this.getDate(order.date),
            order.status.toUpperCase(),
            this.getUploadButton(order.order_id, order.status),
            this.getOngoingActionButton(order.order_id, order.status),
          ];
        });
    } else if (this.state.orderStatus === 'Completed Orders') {
      orders = this.props.orders
        .filter((order) => {
          if (order.status === 'completed') {
            return true;
          }
          return false;
        })
        .map((order) => {
          return [
            order.order_id,
            order.name,
            order.email,
            15000,
            this.getModelName(order.model_uid),
            this.getDate(order.date),
            order.status,
          ];
        });
    } else if (this.state.orderStatus === 'Rejected Orders') {
      orders = this.props.orders
        .filter((order) => {
          if (order.status === 'rejected') {
            return true;
          }
          return false;
        })
        .map((order) => {
          return [
            order.order_id,
            order.name,
            order.email,
            15000,
            this.getModelName(order.model_uid),
            this.getDate(order.date),
            order.status.toUpperCase(),
          ];
        });
    }
    return orders;
  };

  render() {
    return (
      <React.Fragment>
        <ThemeProvider theme={Theme}>
          <DashboardNavbar />
          <br />
          <br />
          <br />
          <div id="orderTable">
            <Tabs
              value={this.state.tabIndex}
              onChange={this.onTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="New Orders" />
              <Tab label="Ongoing Orders" />
              <Tab label="Completed Orders" />
              <Tab label="Rejected Orders" />
            </Tabs>
            <MUIDataTable
              title={this.state.orderStatus}
              data={this.getOrdersToShow()}
              columns={this.getColumns()}
              options={options}
            />
          </div>
          {this.getPopup()}
          {this.getUploadPopup()}
          {this.getOngoingPopup()}
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    orders: { orders, costings = [] },
  } = state;
  return {
    user: state.auth.user
      ? state.auth.user
      : JSON.parse(sessionStorage.getItem('lotteryUser')),
    orders,
    costings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllOrders: () => {
      getOrders(dispatch);
    },
    getAllCosting: () => {
      getCosting(dispatch);
    },
    acceptOrder: (selectedOrderId, selectedOrderStatus, acceptanceDate) => {
      return updateOrder(selectedOrderId, selectedOrderStatus, acceptanceDate);
    },
    uploadFile: (formData) => {
      return updateUpload(formData);
    },
    uploadDeliveryStatus: (selectedOrderId, onGoingStatus) => {
      return updateOngoingOrder(selectedOrderId, onGoingStatus);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
