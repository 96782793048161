import axios from 'axios';
import serverConstants from '../assets/data/serverConstants';
import {GET_BLOG} from '../store/actions/actionTypes';

export const getAllBlogs = (dispatch) => {
    const url = serverConstants.serverUrl + 'getAllBlogs';
    const headers = {
        Authorization: sessionStorage.getItem('epanipuriToken'),
      };
    return axios.get(url, {headers}).then((response) => {
        dispatch({
            type: GET_BLOG,
            payload: response.data.blogs
        })
    });
}

export const deleteBlog = (blogId) => {
    const deleteBlogUrl = serverConstants.serverUrl + 'deleteBlog';
    const param = {
        blogId
    }
    const header = {
        Authorization: sessionStorage.getItem('epanipuriToken'),
    };
    return axios.post(deleteBlogUrl, param, { headers: header })

}