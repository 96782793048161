export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const GET_ORDER = "GET_ORDER";
export const GET_COSTING = "GET_COSTING";
export const API_ERROR = "API_ERROR";
export const GET_BLOG = "GET_BLOG";
export const DELETE_BLOG = "DELETE_BLOG";
export const GET_PENDING_FORM = "GET_PENDING_FORM";
export const GET_STOCKS = "GET_STOCKS";
export const GET_LOGS = "GET_LOGS"
export const GET_RETAINER_INVOICE='GET_RETAINER_INVOICE'
