import axios from 'axios';
import serverConstants from '../assets/data/serverConstants';
import {GET_RETAINER_INVOICE} from '../store/actions/actionTypes';

export const getRetainerInvoices = (dispatch) => {
    const url = serverConstants.serverUrl + 'v2/getRetainerInvoices';
    const headers = {
        Authorization: sessionStorage.getItem('epanipuriToken'),
      };
    return axios.get(url, {headers}).then((response) => {
        dispatch({
            type: GET_RETAINER_INVOICE,
            payload: response.data.retainerInvoices
        })
    });
}


export const markAsPaid = (invoiceId) => {
    const acceptRejectFormUrl = serverConstants.serverUrl + 'v2/markAsPaid';
    const header = {
        Authorization: sessionStorage.getItem('epanipuriToken'),
    };
    return axios.post(acceptRejectFormUrl, invoiceId, { headers: header })

}
