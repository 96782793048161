import { makeStyles } from "@material-ui/core/styles";
const drawerWidth = 240;

const styles = makeStyles((theme) => ({

    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: "100%",
    },
    textField1: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginInlineStart: theme.spacing(20),
        width: "50%",
    },
    mainLogo: {
        width: "180px",
        height: "200px",
        marginTop: theme.spacing(5),
    },
    button: {
        width: "80%",
    },
    button2: {
        width: "40%",
    },
    data: {
        marginTop: theme.spacing(5)
    },
    textHeading: {
        display: "inline-block"
    },
    text: {
        display: "inline-block",
        fontWeight: "normal"
    },
    root: {
        flexGrow: 1
    },
/*     title: {
        flexGrow: 1
    },
    logo: {
        marginRight: theme.spacing(2)
    }, */

    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        flexShrink: 0
    },
    list: {
        padding: 0
    },
    title: {
        flexGrow: 1
    },
    logoutProgress: {
        color: "white"
    },
    logo: {
        marginRight: theme.spacing(2)
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

}));

export default styles;