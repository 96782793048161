import * as actionTypes from '../actions/actionTypes';

const initialState = {
    blogs: []
};

const manageBlogReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_BLOG:
            return {
                ...state,
                blogs: action.payload
            }
        case actionTypes.DELETE_BLOG:
            return {
                blogs: action.payload,
                ...state
            }
        default:
            return state;
    }
};

export default manageBlogReducer;