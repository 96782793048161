import React, { useState } from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { ThemeProvider } from "@material-ui/styles";
import clsx from "clsx";
import epanipuricart from "../assets/images/epanipuricart.png";
import * as actions from "../store/actions/index";
import history from "../hoc/utils/history";
import Theme from "../hoc/UI/Theme";
import Styles from "../hoc/UI/Styles";

const DashboardNavbar = (props) => {
  const classes = Styles();

  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const [Loading, setLoading] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    setLoading(true);
    props.logout();
    setLoading(false);
    history.push("/");
  };

  const handleClick = (name) => {
    switch (name) {
      case "Home":
        history.push("/home");
        break;
      case "Book List":
        history.push("/booklist");
        break;
      case "Game List":
        history.push("/gameList");
        break;
      case "Manage Stats":
        history.push("/statistics");
        break;
      case "Manage Pricing":
        history.push("/pricing");
        break;
      case "Upload Blog":
        history.push("/blog");
        break;
      case "Manage Blog":
        history.push("/manageBlog");
        break;
      case "Users":
        history.push("/user");
        break;
      case "Manage Stocks":
        history.push("/manageStock");
        break;
        case "Retainer Invoices":
          history.push("/retainerinvoice");  
          break; 
      default:
        break;
    }
  };
  const listOfRoles = JSON.parse(sessionStorage.getItem("lotteryUser"));
  // const userRoles = listOfRoles?.roles[0];
  const navMenu = () => {
    let navMenuList = [
      "Home",
      "Users",
      "Book List",
      "Game List",
      // "View Orders",
      // "Manage Stats",
      // "Manage Pricing",
      // "Upload Blog",
      // "Manage Blog",
      // "Manage Stocks"
    ]

    return navMenuList;
  };

  const drawer = (
    <React.Fragment>
      <List className={classes.list}>
        {navMenu().map((name, index) => (
          <React.Fragment key={name}>
            <Divider />
            <ListItem button key={name} onClick={() => handleClick(name)}>
              <Typography variant="subtitle1">{name}</Typography>
            </ListItem>
          </React.Fragment>
        ))}
        <Divider />
      </List>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <ThemeProvider theme={Theme}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: mobileOpen,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={epanipuricart}
              alt="logo"
              width="50"
              height="50"
              className={classes.logo}
            />
            <Typography variant="h6" className={classes.title}>
            Jagaran Pustika Online Marketing
            </Typography>
            {Loading ? (
              <CircularProgress className={classes.logoutProgress} />
            ) : (
              <Button color="inherit" onClick={handleLogout}>
                Log out
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          <Hidden smUp implementation="css">
            <Drawer
              className={classes.drawer}
              variant="persistent"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              className={classes.drawer}
              variant="persistent"
              anchor="left"
              open={mobileOpen}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      </ThemeProvider>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default connect(null, mapDispatchToProps)(DashboardNavbar);
