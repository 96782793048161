import * as actionTypes from '../actions/actionTypes';

const initialState = {
    logs: []
};

const logsReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_LOGS:
            return {
                ...state,
                logs: action.payload
            }
        default:
            return state;
    }
};

export default logsReducer;