import React from 'react';
import { connect } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { ThemeProvider } from '@material-ui/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DashboardNavbar from './DashboardNavbar';
import '../hoc/UI/orders.css';
import 'axios';
import Theme from '../hoc/UI/Theme';
import {
  getAllBlogs,deleteBlog
} from '../api/ManageBlogApi';
import Button from '@material-ui/core/Button';

const options = {
  filter: false,
  download: false,
  print: false,
  viewColumns: false,
  selectableRows: 'none',
};
class ManageBlog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {     
      blogTitle: 'Manage Blogs',
      isPopupOpened: false,
      selectedBlogId: undefined,
      selectedBlogName: undefined,
      open: false,
      snackOpen: false
      
    };
  }
  componentDidMount() {
    this.props.getAllBlogs();
  }

  onActionClicked = (id, name) => {
    this.setState(
      {
        selectedBlogId: id,
        selectedBlogName: name,
      },
      () => {
        this.setState({
          isPopupOpened: true,
        });
      }
    );
  };



  getColumns = () => {
      return [
        'Title',
        'Date',
        'Action',
      ];
    
  };

  getActionButton = (blogId , name) => {
    return (
      <Button
        onClick={() => {
          this.onActionClicked(blogId, name);
        }}
        variant="contained"
        color="primary"
        className="btn-danger"
      >
        Delete
      </Button>
    );
  };



  getDate = (date) => {
    let newDate = new Date(date);
    return newDate.toDateString();
  };


  handleClose = () => {
    this.setState({
      isPopupOpened: false,
      selectedBlogId: undefined,
      selectedBlogName: undefined
    });
  };


  handleDeleteBlog = () => {

    this.props
      .deleteBlog(this.state.selectedBlogId)
      .then(() => {
        this.props.getAllBlogs();
        this.setState({
          isPopupOpened: false,
          selectedBlogId: undefined,
          selectedBlogName: undefined
        });
      });
  };

  getPopup = () => {
    return (
      <Dialog
        open={this.state.isPopupOpened}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent className="dialog-content">
          Are you sure you want to delete <b>{this.state.selectedBlogName}</b> ?
          <Grid container spacing={3} style={{paddingTop: "20px"}}>
            <Grid item xs={12} sm={6} >
              <Button
                onClick={this.handleDeleteBlog}
                variant="contained"
                color="primary"
              >
                Yes
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                className="reject-button"
                onClick={this.handleClose}
                variant="contained"
              >
                No
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    );
  };



  getBlogsToShow = () => {
    let blogs = [];
      blogs = this.props.blogs
        .map((blog) => {
          return [
            blog.title,
            this.getDate(blog.date),
            this.getActionButton(blog.blogId,blog.title),
          ];
        });
    
    return blogs;
  };

  render() {
    return (
      <React.Fragment>
        <ThemeProvider theme={Theme}>
          <DashboardNavbar />
          <br />
          <br />
          <br />
          <div id="blogTable">
            <MUIDataTable
              title={this.state.blogTitle}
              data={this.getBlogsToShow()}
              columns={this.getColumns()}
              options={options}
            />
          </div>
          {this.getPopup()}
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    blogs: { blogs },
  } = state;
  return {
    user: state.auth.user
      ? state.auth.user
      : JSON.parse(sessionStorage.getItem('lotteryUser')),
    orders:state.orders,
    costings:state.costings,
    blogs
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllBlogs: () => {
        getAllBlogs(dispatch);
    },
    deleteBlog: (blogId) => {
      return deleteBlog(blogId);
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageBlog);
