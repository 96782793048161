import * as actionTypes from "./actionTypes";

export const login = (user) => {
    return {
        type: actionTypes.LOG_IN, 
        payload: user
    }
};

export const logout = () => {
    return {
        type: actionTypes.LOG_OUT, 
    }
};