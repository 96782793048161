import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DashboardNavbar from './DashboardNavbar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Theme from '../hoc/UI/Theme';
import { ThemeProvider } from '@material-ui/styles';
import serverConstants from '../assets/data/serverConstants';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getPricing, updatePricing } from '../api/OrderApi';
import SearchBar from 'material-ui-search-bar';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    paper: {
        width: '90%',
        margin: 'auto'
    },
    button: {
        // justifyContent:'flex-end'
    }
});

const BookList = (props) => {
    const classes = useStyles();

    
    const [bookList, setBookList] = useState();
    const [popup, setPopup] = React.useState(false);
    const [values, setValues] = useState({
        name: '',
        price: '',
        totalPage:'',
        number:'',
        bookSKU:'',
    });
    const [Loading, setLoading] = useState(false);

    useEffect(() => {
        getBookList();
    }, []);

    const getBookList = () => {
        let getPricingUrl = serverConstants.baseURL + '/api/book/allBook';
        const headers = {"Content-Type":"application/json",      'ngrok-skip-browser-warning': 'true'     };
        return axios.get(getPricingUrl, { headers: headers }).then(response=>{
            console.log('response',response.data.data);
            setBookList(response.data.data);
          }).catch(error=>{
            // alert("No data found")
          })
        
    }

    const openPopup = () => {
        setPopup(true);
        setLoading(false);
    };

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const checkValidation = ()=>{
        if (values.name == '') {
            alert("Please enter book name")
            return false
        } else if (values.price == '') {
            alert("Please enter price")
            return false
        }else if (values.bookSKU == '') {
            alert("Please enter book sku")
            return false
        }else if (values.totalPage == '') {
            alert("Please enter total page")
            return false
        }

        return true
    }

    const updateData = () => {
        setLoading(true);

        
        if(!checkValidation()){
            return
        }

        const addBookData = {
            "name": values.name,
            "totalPage": values.totalPage,
            "bookSKU": values.bookSKU,
            "price": values.price
        }

        let addBook = serverConstants.baseURL + '/api/book/addBook';
        const headers = {"Content-Type":"application/json",'ngrok-skip-browser-warning': 'true'     };
        return axios.post(addBook,addBookData, { headers: headers }).then(response=>{
            console.log('response',response.data);
            // setBookList(response.data.data);
            getBookList();
            alert("Book data added successfully!")
            closePopup()
          }).catch(error=>{
            alert("Something went wrong, please try again!")
            closePopup()
            // alert("No data found")
          })
    }

    const closePopup = () => {
        setPopup(false);
    }

    return (
        <React.Fragment>
            <DashboardNavbar />
            <br />
            <br />
            <br />

            <Paper
                className={classes.paper}
                elevation={3}
                style={{ marginTop: '2rem' }}
            >
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 10 }}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        onClick={() => {
                            openPopup();
                        }}
                    >
                        {' '}
                        Add New Book
                    </Button>
                </div>
                <Table
                    className={classes.table}
                    size="small"
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <th>Sl.No</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Book name</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Book Number</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Price</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Total Page</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Created</th>
                            </TableCell>
                            {/* <TableCell align="left">
                <th>Modify</th>
              </TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bookList &&
                            bookList.map((row, index) => (
                                <TableRow key={row.uid}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{row.number}</TableCell>
                                    <TableCell align="left">{row.price}</TableCell>
                                    <TableCell align="left">{row.totalPage}</TableCell>
                                    <TableCell align="left">{row.createdAt}</TableCell>
                                    {/* <TableCell align="left">
                    <ThemeProvider theme={Theme}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        onClick={() => openPopup(row.uid,row.name,row.price)}
                      >
                        {' '}
                        Update
                      </Button>
                    </ThemeProvider>
                  </TableCell> */}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </Paper>
            <Dialog
                open={popup}
                onClose={() => setPopup(false)}
                aria-labelledby="form-dialog-title" >
                <DialogTitle id="form-dialog-title">
                    Add Book
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="Name"
                        type="text"
                        name="name"
                        value={values.name}
                        margin="dense"
                        fullWidth
                        multiline
                        rowsMax={10}
                        required={true}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Pricing"
                        type="number"
                        name="price"
                        value={values.price}
                        margin="dense"
                        fullWidth
                        required={true}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Book SKU"
                        type="text"
                        name="bookSKU"
                        value={values.bookSKU}
                        margin="dense"
                        fullWidth
                        required={true}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Total Page"
                        type="number"
                        name="totalPage"
                        value={values.totalPage}
                        margin="dense"
                        fullWidth
                        required={true}
                        onChange={handleChange}
                    />
                    
                </DialogContent>
                <DialogActions>
                    {Loading ? (
                        <CircularProgress />
                    ) : (
                        <ThemeProvider theme={Theme}>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                className={classes.button}
                                onClick={closePopup}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                className={classes.button}
                                onClick={updateData}
                            >
                                Save
                            </Button>
                        </ThemeProvider>
                    )}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
            ? state.auth.user
            : JSON.parse(sessionStorage.getItem('lotteryUser')),
    };
};

export default connect(mapStateToProps)(BookList);
