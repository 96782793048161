import axios from 'axios';
import serverConstants from '../assets/data/serverConstants';
import {GET_ORDER, GET_COSTING} from '../store/actions/actionTypes';

export const getOrders = (dispatch) => {
    const url = serverConstants.serverUrl + 'getAllOrders';
    const headers = {
        Authorization: sessionStorage.getItem('epanipuriToken'),
      };
    return axios.get(url, {headers}).then((response) => {
        dispatch({
            type: GET_ORDER,
            payload: response.data.orders
        })
    });
}

export const getCosting = (dispatch) => {
    const url = serverConstants.serverUrl + 'getCosting';
    const headers = {
        Authorization: sessionStorage.getItem('epanipuriToken'),
      };
    return axios.get(url, {headers}).then((response) => {
        dispatch({
            type: GET_COSTING,
            payload: response.data.items
        })
    });
}

export const updateOrder = (order_id, status, deliveryDate) => {
    const url = serverConstants.serverUrl + 'updateOrder';
    const headers = {
        Authorization: sessionStorage.getItem('epanipuriToken'),
      };
    const data = {
        order_id,
        status,
        deliveryDate
    }
    return axios.post(url, data, {headers}).then((response) => {
        return new Promise((res, rej) => {
            return res(response);
        });
    });
}

export const updateOngoingOrder = (order_id, status) => {
    const url = serverConstants.serverUrl + 'updateOrder';
    const headers = {
        Authorization: sessionStorage.getItem('epanipuriToken'),
      };
    const data = {
        order_id,
        status,
    }
    return axios.post(url, data, {headers}).then((response) => {
        return new Promise((res, rej) => {
            return res(response);
        });
    }).catch((err)=>{
        console.log(err)
    });
}

export const updateUpload = (formData) => {
    const url = serverConstants.serverUrl + 'uploadAgreement';
    const headers = {
        Authorization: sessionStorage.getItem('epanipuriToken'),
      };
    return axios.post(url, formData, {headers}).then((response) => {
        return new Promise((res, rej) => {
            return res(response);
        });
    });
}

export const getPricing = () => {
    let getPricingUrl = serverConstants.serverUrl + 'getCosting';
    const headers = {
      Authorization: sessionStorage.getItem('epanipuriToken'),
    };
    return axios
      .get(getPricingUrl, { headers: headers })
    
}

export const updatePricing = (updateData) => {
    const updateUrl = serverConstants.serverUrl + 'updateCosting';
    const headers = {
      Authorization: sessionStorage.getItem('epanipuriToken'),
    };
    return axios
      .post(updateUrl, updateData, { headers: headers })
    
}

export const updateBlog = (formData) => {
    const saveBlogUrl = serverConstants.serverUrl + 'saveBlog';
    const header = {
        Authorization: sessionStorage.getItem('epanipuriToken'),
    };
    return axios.post(saveBlogUrl, formData, { headers: header })

}
