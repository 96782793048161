import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DashboardNavbar from './DashboardNavbar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Theme from '../hoc/UI/Theme';
import { ThemeProvider } from '@material-ui/styles';
import serverConstants from '../assets/data/serverConstants';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getPricing, updatePricing } from '../api/OrderApi';
import SearchBar from 'material-ui-search-bar';
import moment from 'moment';
import history from "../hoc/utils/history";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    paper: {
        width: '90%',
        margin: 'auto'
    },
    button: {
        // justifyContent:'flex-end'
    }
});

const BalanceHisotry = (props) => {
    const classes = useStyles();

    
    const [gameList, setGameList] = useState();
    const [popup, setPopup] = React.useState(false);
    const [values, setValues] = useState({
        walletBalance: '',
        startTime: '',
        endTime:'',
        // number:'',
        // bookSKU:'',
    });
    const [Loading, setLoading] = useState(false);

    useEffect(() => {
        console.log('props.history.location.state?.data',props.history.location.state?.data);
        getWalletHistory();
    }, []);

    const getWalletHistory =async () => {
        let getPricingUrl = serverConstants.baseURL + '/api/wallet/walletHistory/'+props.history.location.state?.data;
        const headers = {"Content-Type":"application/json",      'ngrok-skip-browser-warning': 'true'     };
        return axios.get(getPricingUrl, { headers: headers }).then(response=>{
            console.log('response',response.data.data);
            let dataToShow =[...response.data.data.mainHistory,...response.data.data.walletHistory];
            dataToShow = dataToShow.sort((a,b)=> new Date(a.date).getTime()- new Date(b.date).getTime());
            setGameList(dataToShow);
          }).catch(error=>{
            // alert("No data found")
          })
        
    }

    const openPopup = () => {
        setPopup(true);
        setLoading(false);
    };

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const checkValidation = ()=>{
        if (values.walletBalance == '') {
            alert("Please enter amount")
            return false
        } 
        // else if (values.startTime == '') {
        //     alert("Please enter start time")
        //     return false
        // }else if (values.endTime == '') {
        //     alert("Please enter end time")
        //     return false
        // }
        // else if (values.totalPage == '') {
        //     alert("Please enter total page")
        //     return false
        // }

        return true
    }

    const updateData = () => {
        setLoading(true);

        
        if(!checkValidation()){
            return
        }

        const addBalanceData = {
            "userId": props.history.location.state?.data,
            "walletBalance": Number(values.walletBalance),
            // "endTime":values.endTime
        }

        let addBalanceURL = serverConstants.baseURL + '/api/wallet/addBalance';
        const headers = {"Content-Type":"application/json",'ngrok-skip-browser-warning': 'true'     };
        return axios.post(addBalanceURL,addBalanceData, { headers: headers }).then(response=>{
            console.log('response',response.data);
            // setGameList(response.data.data);
            getWalletHistory();
            alert("Balance data added successfully!")
            closePopup()
          }).catch(error=>{
            alert("Something went wrong, please try again!")
            closePopup()
            // alert("No data found")
          })
    }

    const closePopup = () => {
        setPopup(false);
    }

    const onViewOrder = (item)=>{
        history.push("/orderDetail",{data:item})
    }

    return (
        <React.Fragment>
            <DashboardNavbar />
            <br />
            <br />
            <br />

            <Paper
                className={classes.paper}
                elevation={3}
                style={{ marginTop: '2rem' }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: 5 }}>
                <h3 style={{}}>{"Transaction History"}</h3>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        onClick={() => {
                            openPopup();
                        }}
                    >
                        Add Balance
                    </Button>
                </div>
                <Table
                    className={classes.table}
                    size="small"
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <th>Sl.No</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Date</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Amount</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Type</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Wallet Type</th>
                            </TableCell>
                            {/* <TableCell align="left">
                                <th>Created</th>
                            </TableCell>
                            <TableCell align="left">
                                <th>Action</th>
                            </TableCell> */}
                            {/* <TableCell align="left">
                <th>Modify</th>
              </TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {gameList &&
                            gameList.map((row, index) => (
                                <TableRow key={row.uid}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="left">{moment(row.date).format("DD/MM/YYYY hh:mm A")}</TableCell>
                                    <TableCell align="left">{row.amount}</TableCell>
                                    <TableCell align="left">{row.transactionType}</TableCell>
                                    <TableCell align="left">{row.balanceType}</TableCell>
                                    {/* <TableCell align="left">{row.createdAt}</TableCell>
                                    <TableCell align="left">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            className={classes.button}
                                            onClick={() => onViewOrder(row)}
                                        >
                                            View Order
                                        </Button>
                                    </TableCell> */}
                                    {/* <TableCell align="left">
                    <ThemeProvider theme={Theme}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        onClick={() => openPopup(row.uid,row.name,row.price)}
                      >
                        {' '}
                        Update
                      </Button>
                    </ThemeProvider>
                  </TableCell> */}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </Paper>
            <Dialog
                open={popup}
                onClose={() => setPopup(false)}
                aria-labelledby="form-dialog-title" >
                <DialogTitle id="form-dialog-title">
                    Add Balance
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="Amount"
                        type="Number"
                        name="walletBalance"
                        value={values.walletBalance}
                        margin="dense"
                        fullWidth
                        // multiline
                        // rowsMax={10}
                        required={true}
                        onChange={handleChange}
                    />
{/*                     
                    <TextField
                        label="Start Time"
                        type="time"
                        name="startTime"
                        value={values.startTime}
                        margin="dense"
                        fullWidth
                        required={true}
                        onChange={handleChange}
                    />
                    
                    <TextField
                        label="End Time"
                        type="time"
                        name="endTime"
                        value={values.endTime}
                        margin="dense"
                        fullWidth
                        required={true}
                        onChange={handleChange}
                    /> */}

                    {/* <TextField
                        label="Total Page"
                        type="number"
                        name="totalPage"
                        value={values.totalPage}
                        margin="dense"
                        fullWidth
                        required={true}
                        onChange={handleChange}
                    /> */}
                    
                </DialogContent>
                <DialogActions>
                    {Loading ? (
                        <CircularProgress />
                    ) : (
                        <ThemeProvider theme={Theme}>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                className={classes.button}
                                onClick={closePopup}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                className={classes.button}
                                onClick={updateData}
                            >
                                Save
                            </Button>
                        </ThemeProvider>
                    )}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
            ? state.auth.user
            : JSON.parse(sessionStorage.getItem('lotteryUser')),
    };
};

export default connect(mapStateToProps)(BalanceHisotry);
