import * as actionTypes from '../actions/actionTypes';

const initialState = {
    orders: []
};

const orderReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_ORDER:
            return {
                ...state,
                orders: action.payload
            }
        case actionTypes.GET_COSTING:
            return {
                ...state,
                costings: action.payload
            }
        default:
            return state;
    }
};

export default orderReducer;