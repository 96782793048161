import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import DashboardNavbar from './DashboardNavbar';
import Styles from '../hoc/UI/Styles';
import Grid from '@material-ui/core/Grid';

const Home = (props) => {
  const classes = Styles();

  return (
    <React.Fragment>
      <DashboardNavbar />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={5}>
          <Typography variant="h4" align="center">
            Welcome {props.user ? props.user.name : ''} !
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user ? state.auth.user : JSON.parse(sessionStorage.getItem('lotteryUser'))
  };
};

export default connect(mapStateToProps)(Home);
