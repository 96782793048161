import * as actionTypes from '../actions/actionTypes';

const initialState = {
    forms: []
};

const pendingFormReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_PENDING_FORM:
            return {
                ...state,
                forms: action.payload
            }

        default:
            return state;
    }
};

export default pendingFormReducer;